import { useContext, useRef } from "react";
import { Context } from "../../Store";
import classes from "./PlotToggleButtons.module.css";
import { GetLengthProfilePlots } from "../../utils/PlotUtils";

const PlotToggleButtons = () => {
  const [state, dispatch] = useContext(Context);

  const lengdeprofilRef = useRef();
  const tverrsnittRef = useRef();

  const toolChangeHandler = (event) => {
    let currentPlot = "none";
    state.mapview.graphics.removeAll();

    if (state.plotInfo.plots) {
      dispatch({ type: "RESET_PLOT" });
      // remove old graphic
      const intersectLineLayer = state.mapview.map.findLayerById(
        state.intersectLineLayerId
      );
      if (intersectLineLayer) intersectLineLayer.removeAll();
    }
    if (event.target === lengdeprofilRef.current && event.target.checked) {
      tverrsnittRef.current.checked = false;
      currentPlot = "lengdeprofil";
      if (state.currentRoadModel.name) {
        GetLengthProfilePlots(
          state.mapview,
          state.currentProject.intersectLayout,
          state.centerLine,
          state.currentProject.name,
          state.currentRoadModel,
          dispatch
        );
      }
    }
    if (event.target === tverrsnittRef.current && event.target.checked) {
      lengdeprofilRef.current.checked = false;
      currentPlot = "tverrsnitt";
    }

    dispatch({ type: "SET_PLOTTER_TYPE", plotter: currentPlot });
  };

  return (
    <div className={classes.toolbar}>
      <label>
        <input
          className={classes.input}
          ref={tverrsnittRef}
          type="checkbox"
          value="1"
          onChange={toolChangeHandler}
        ></input>
        <img
          className={classes.button_image}
          src="/images/tverrsnitt.png"
          alt="Åpne tverrsnitplott"
        />
      </label>
      <label>
        <input
          className={classes.input}
          ref={lengdeprofilRef}
          type="checkbox"
          value="1"
          onChange={toolChangeHandler}
        ></input>
        <img
          className={classes.button_image}
          src="/images/lengdeprofil.png"
          alt="Åpne lengdeprofil"
        />
      </label>
    </div>
  );
};

export default PlotToggleButtons;
