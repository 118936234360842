import classes from "./MainNav.module.css";
import SelectRoadModelMenu from "./SelectRoadModelMenu";
import SelectProjectMenu from "./SelectProjectMenu";
import { useContext, useState } from "react";
import { Context } from "../../Store";
import ZoomToRoadModelButton from "./ZoomToRoadModelButton";
import PlotToggleButtons from "./PlotToggleButtons";
import IconButton from "@mui/material/IconButton";

const MainNav = (props) => {
  const [state] = useContext(Context);
  const [marker, setMarker] = useState({ profilNr: "-", distance: "-" });

  props.marker.current = (marker) => {
    setMarker(marker);
  };

  return (
    <header className={classes.header}>
      <IconButton onClick={() => window.location.reload()}>
        <img src="/images/logo.png" alt="GISIS logo" />
      </IconButton>
      <ul>
        <li>
          <SelectProjectMenu />
        </li>
        <li>
          <SelectRoadModelMenu />
        </li>
      </ul>
      <ZoomToRoadModelButton />
      <PlotToggleButtons />
      <div className={classes.veginfo}>
        <table>
          <tbody>
            <tr>
              <td className={classes.profilNr}> Profilnr: </td>
              <td className={classes.prolfilNrVerdi}>{marker.profilNr}</td>
              <td className={classes.avstandSenterlinje}>
                Avstand senterlinje:
              </td>
              <td className={classes.avstandSenterLinjeVerdi}>
                {marker.distance}
              </td>
              <td className={classes.avstandSenterLinjeVerdi}>m</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={classes.projectinfo}>
        <ul>
          <li>
            <div> {state.currentProject.client} </div>
          </li>
          <li>
            <div> {state.currentProject.verticalRef} </div>
          </li>
          <li>
            <div> {state.currentProject.spatialRef} </div>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default MainNav;
