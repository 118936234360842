const ShowOnlyActiveRoadModel = (
  mapview,
  roadModel,
  project,
  showOnlyActiveRoadModel,
  overbygningslag
) => {
  const roadModelLayer = mapview.map.layers.items.find(
    (item) => item.title === "Senterlinje"
  );

  if (roadModelLayer) {
    const karttjenester = mapview.map.layers.items.find(
      (item) => item.title === "Karttjenester"
    );

    if (showOnlyActiveRoadModel) {
      roadModelLayer.definitionExpression = `prosjektnavn = '${project}' AND vegmodellnavn = '${roadModel.name}' AND revisjon = '${roadModel.revisjon}' AND mmi = '${roadModel.mmi}'`;

      karttjenester.allSublayers.forEach((subLayer) => {
        if (
          subLayer.title === "Omriss" ||
          subLayer.title === "Flatekantlinjer"
        ) {
          subLayer.definitionExpression = `overbygningslag = '${overbygningslag}' AND prosjektnavn = '${project}' AND vegmodellnavn = '${roadModel.name}' AND revisjon = '${roadModel.revisjon}' AND mmi = '${roadModel.mmi}'`;
        }
      });
    } else {
      roadModelLayer.definitionExpression = `prosjektnavn = '${project}'`;
      karttjenester.allSublayers.forEach((subLayer) => {
        if (
          subLayer.title === "Omriss" ||
          subLayer.title === "Flatekantlinjer"
        ) {
          subLayer.definitionExpression = `overbygningslag = '${overbygningslag}' AND prosjektnavn = '${project}'`;
        }
      });
    }
  } else console.log("no layer found ");
};

export default ShowOnlyActiveRoadModel;
