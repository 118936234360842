import * as watchUtils from "@arcgis/core/core/watchUtils";
/**
 * Fetch height data from relevant layers.
 *
 * @param {basemapToggleExpand} expand to the basemapToggle widget
 * @param {measurementExpand} expand to the measurement widget
 *
 *
 */

//handles basemapToggleExpand
export const expandHandler = (basemapToggleExpand, measurementExpand) => {
  const expandHandler1 = watchUtils.pausable(
    basemapToggleExpand,
    "expanded",
    function (newValue) {
      if (newValue === true) {
        expandHandler1.pause();
        setTimeout(function () {
          expandHandler2.resume();
        }, 100);
      } else {
        expandHandler1.resume();
      }
      if (measurementExpand.expanded) {
        measurementExpand.collapse();
      }
    }
  );

  //handles measuremenetExpand
  const expandHandler2 = watchUtils.pausable(
    measurementExpand,
    "expanded",
    function (newValue) {
      if (newValue === true) {
        expandHandler2.pause();
        setTimeout(function () {
          expandHandler1.resume();
        }, 100);
      } else {
        expandHandler2.resume();
      }
      if (basemapToggleExpand.expanded) {
        basemapToggleExpand.collapse();
      }
    }
  );
};
