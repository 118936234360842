import { useContext } from "react";
import { Context } from "../../Store";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";

const Spinner = () => {
  const [state] = useContext(Context);

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const boxStyle = {
    background: "transparent",
    outline: "none",
  };

  return (
    <Modal
      aria-labelledby="busy-modal"
      closeAfterTransition={true}
      style={modalStyle}
      open={state.busy}
      BackdropProps={{ style: { backgroundColor: "rgba(255,255,255,0.5" } }}
    >
      <Fade in={state.busy}>
        <Box sx={boxStyle}>
          <CircularProgress />
        </Box>
      </Fade>
    </Modal>
  );
};

export default Spinner;
