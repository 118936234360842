import { calculateProfilNr } from "./CenterLineUtils";
import {
  distance,
  nearestCoordinate,
  planarLength,
  intersects,
  intersect,
} from "@arcgis/core/geometry/geometryEngine";
import { getPointAtProfileNr } from "./IntersectLine";

import { cut } from "@arcgis/core/geometry/geometryEngineAsync";
import Point from "@arcgis/core/geometry/Point";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";

const GetImageServicePlots = async (
  mapview,
  line,
  lineLength,
  clickPoint,
  centerLine
) => {
  const imageryLayers = mapview.map.layers.items.filter(
    (item) =>
      item.type === "imagery" && item.title.toLowerCase().includes("profil_")
  );
  if (!imageryLayers) return [];
  let imageServicePlots = [];
  try {
    const geometry = clickPoint ? line : line.geometry;
    const param = {
      geometry: geometry,
      pixelSize: {
        x: 1,
        y: 1,
        spatialReference: mapview.spatialReference.wkid,
      },
      sampleDistance: 1,
      outFields: ["*"],
    };

    const promises = imageryLayers.map(async (layer) => {
      const results = await layer.getSamples(param);
      results.samples.sort((a, b) => (a.locationId > b.locationId ? 1 : -1));

      const plot = ConvertToPlot(
        results,
        lineLength,
        line,
        clickPoint,
        centerLine
      );

      plot.type = "scatter";
      plot.mode = "lines+markers";
      plot.marker = {
        color: layer.title === "profil_GeomapDTM" ? "DarkGreen" : "rgb(0,0,0)",
        size: 1,
      };

      plot.type = "scatter";
      plot.name = layer.title === "profil_GeomapDTM" ? "Terreng" : layer.title;
      plot.showlegend = true;

      imageServicePlots.push(plot);
      return plot;
    });

    await Promise.all(promises);
  } catch (e) {
    alert("Failed to fetch data from one or more image server(s).");
    console.log("Failed to fetch  one or more image server plots");
    console.log(e.message);
  }
  return imageServicePlots;
};

const ConvertToPlot = (
  plotFeature,
  linelength,
  line,
  clickPoint,
  centerLine
) => {
  let [x, y] = [[], []];

  if (clickPoint) {
    x = plotFeature.samples.map((item) => {
      let value = Math.abs(Number(item.locationId) - Number(linelength) / 2);
      value = getXvalue(item.location, value, centerLine, line);
      return value;
    });
  } else {
    let profilNr = line.attributes.start_profilnr;
    let prevPoint = null;
    x = plotFeature.samples.map((item) => {
      let point = new Point({
        type: "point",
        x: item.location.x,
        y: item.location.y,
      });

      if (prevPoint) {
        profilNr += distance(point, prevPoint);
        prevPoint = point;
        return profilNr;
      }
      prevPoint = point;
      return profilNr;
    });
  }
  y = plotFeature.samples.map((item) => Number(item.pixelValue));
  const result = {
    x: x,
    y: y,
  };
  return result;
};

// Returns layout.shapes object
export const GetClickPointLine = (
  plotterType,
  xValue,
  lineLength = Infinity
) => {
  if (plotterType === "lengdeprofil")
    return {
      type: "line",
      x0: xValue,
      y0: 0,
      x1: xValue,
      y1: 1,
      yref: "paper",
      line: {
        color: "rgb(0,120,201)",
        width: 2,
      },
    };
  else if (plotterType === "tverrsnitt")
    if (Math.abs(xValue) < lineLength / 2)
      return {
        type: "line",
        x0: xValue,
        y0: 0,
        x1: xValue,
        y1: 1,
        yref: "paper",
        line: {
          color: "rgb(0,120,201)",
          width: 2,
        },
      };
  return null;
};

// Returns an array of linesegments that includes the overlapping segments between the intersection line and the 'Omriss' layer.
const getOmrissLine = async (
  mapview,
  line,
  currentProject,
  currentRoadModel
) => {
  // Get omriss lines
  let omrissLine = null;
  const karttjenester = mapview.map.layers.items.find(
    (item) => item.title === "Karttjenester"
  );
  const omrissLayer = karttjenester.allSublayers.find((subLayer) => {
    return subLayer.title === "Omriss";
  });

  if (omrissLayer) {
    const omrissQuery = omrissLayer.createQuery();
    omrissQuery.geometry = line;
    omrissQuery.spatialRelationship = "intersects";
    omrissQuery.returnGeometry = true;
    omrissQuery.returnExceededLimitFeatures = true;
    omrissQuery.returnZ = true;
    omrissQuery.where = `overbygningslag = 'Overflate' AND prosjektnavn = '${currentProject}' AND vegmodellnavn = '${currentRoadModel.name}' AND revisjon = '${currentRoadModel.revisjon}' AND mmi = '${currentRoadModel.mmi}'`;

    await omrissLayer.queryFeatures(omrissQuery).then((result) => {
      if (result.features.length === 1) {
        result.features.forEach((feature) => {
          const intersection = intersect(feature.geometry, line);

          // Find the line intersecting the clickpoint
          if (intersection.paths.length === 1) {
            omrissLine = intersection;
          } else if (intersection.paths.length === 2) {
            const intersect1 = new Polyline({
              paths: [intersection.paths[0]],
              spatialReference: mapview.spatialReference,
            });
            const intersect2 = new Polyline({
              paths: [intersection.paths[1]],
              spatialReference: mapview.spatialReference,
            });

            if (intersects(line.getPoint(0, 1), intersect1)) {
              omrissLine = intersect1;
            } else {
              omrissLine = intersect2;
            }
          } else {
            console.log(
              "Håndter mer enn 2 unike kryssningslinjer med Omriss: ",
              intersection
            );
          }
        });
      }
    });

    return omrissLine;
  } else {
    console.log("Fant ingen overlapp mellom tverssnittet og omrisset");
    return null;
  }
};

// Fetch height data from relevant layers and generate respective plots
export const GetIntersectionPlots = async (
  mapview,
  line,
  layout,
  centerLine,
  currentProject,
  currentRoadModel
) => {
  const centerLinePoint = line.getPoint(0, 1);

  // Relevant data sources
  const conditions = ["_tm_", "3d", "flatekantlinjer"]; // "senterlinje"
  let plots = [];

  //  Get the overlapping line segment between the 'omriss' and the intersection line
  const omrissLine = await getOmrissLine(
    mapview,
    line,
    currentProject,
    currentRoadModel
  );

  // Loop through existing layers
  const layerPromises = mapview.map.layers.items.map(async (item) => {
    if (!item.title) return false;

    // Check if the layer title includes one of the conditional strings
    if (
      conditions.some((string) => item.title.toLowerCase().includes(string))
    ) {
      //Use the overlapping part between the intersection line and the 'omriss' as query geometry for 'Flatekantlinjer'
      const queryLine =
        item.title === "profil_Flatekantlinjer" && omrissLine
          ? omrissLine
          : line;

      // Query intersecting lines
      const query = item.createQuery();
      query.geometry = queryLine;
      query.spatialRelationship = "intersects";
      query.returnGeometry = true;
      query.returnExceededLimitFeatures = true;
      query.returnZ = true;
      if (
        item.title === "profil_Flatekantlinjer" &&
        currentRoadModel.name !== ""
      ) {
        query.where = `prosjektnavn = '${currentProject}' AND vegmodellnavn = '${currentRoadModel.name}' AND revisjon = '${currentRoadModel.revisjon}' AND mmi = '${currentRoadModel.mmi}'`;
        if (item.definitionExpression) {
          query.where = `${item.definitionExpression} AND ${query.where}`;
        }
      }

      // Check if the layer contains 3D constructions for special handling
      const threeDimensional = item.title.toLowerCase().includes("3d");

      // Query intersectin features
      return item.queryFeatures(query).then(async (response) => {
        // Check if the layer intersects the line at the current position
        if (response.features.length !== 0) {
          // For each intersecting line
          const featurePromises = response.features.map(async (feature) => {
            let name = null;
            let text = null;
            let group = null;
            let layoutName = null;
            let useGroupInLegend = false;

            // Find correct plot-name various attributes from various layers
            if (feature.attributes.overbygningslag) {
              if (item.title === "Senterlinje") {
                if (
                  feature.attributes.globalid !== centerLine.attributes.globalid
                ) {
                  return;
                } else {
                  name = "Senterlinje";
                }
              }
              if (!name) {
                name = feature.attributes.overbygningslag;
              }

              if (item.title === "profil_Flatekantlinjer") {
                name = feature.attributes.overbygningslag;
                text = feature.attributes.flatekantnavn;
              }
            } else if (feature.attributes.tm_lag) {
              name = feature.attributes.tm_lag;
            } else if (item.title === "profil_tm_Drone") {
              name = feature.attributes.flight
                ? "Drone flight " + feature.attributes.flight
                : "Drone flight";
              layoutName = "alle flights";
            } else if (item.title === "profil_Konstruksjon 3d") {
              name =
                feature.attributes.filename + " " + feature.attributes.objectid;
              group = feature.attributes.filename;
            } else if (item.title === "profil_Ledninger 3d") {
              name =
                feature.attributes.filename + " " + feature.attributes.objectid;
              group = feature.attributes.filename;
            } else if (item.title === "profil_tm_Ledninger") {
              name =
                feature.attributes.navn + " " + feature.attributes.objectid;
              group = feature.attributes.navn;
            } else if (!name && item.title === "profil_tm_Flatemodeller") {
              name = "Flatemodell";
            } else if (!name && item.title === "profil_tm_Innmålinger TIN") {
              name = "Innmålinger TIN";
            } else {
              name = item.title;
            }

            // Use group as legend name for layer if it exits
            if (group) {
              useGroupInLegend = true;
              // Use plot name as group name if no group exists for the plot
            } else {
              group = name;
            }

            const intersections = threeDimensional
              ? await get3dIntersectionsPoints(queryLine, feature)
              : await getInterserctionPoints(queryLine, feature);

            if (!intersections) {
              console.log(
                "En feil oppstod ved henting av data. Et eller flere punkter kan derfor mangle fra plottet."
              );
            }
            intersections.forEach((intersection) => {
              if (feature.attributes.tm_lag && omrissLine) {
                group = feature.attributes.tm_lag;
                useGroupInLegend = true;
                if (intersects(intersection, omrissLine)) {
                  name = group + "_inside";
                } else {
                  name = group + "_outside";
                }
              }

              // Calculate the distance from the interseciton point to the center if the line
              let distanceToLineCenter = intersection.distance(centerLinePoint);

              distanceToLineCenter = getXvalue(
                intersection,
                distanceToLineCenter,
                centerLine,
                line
              );

              // Add negative and positive "Plastringslag" datapoints to separate plots to avoid connecting lines that crosses the centerline
              if (name === "Plastringslag") {
                layoutName = "plastringslag";
                group = name;
                useGroupInLegend = true;

                if (distanceToLineCenter < 0) {
                  name += "_neg";
                } else {
                  name += "_pos";
                }
              }

              // Check if plot is already created
              let plot = plots.find((plot) => plot.name === name);
              // Only show legendgroupname once in legend
              let showlegend = plots.some((plot) => plot.legendgroup === group)
                ? false
                : true;

              name = showlegend && useGroupInLegend ? group : name;

              // If a plot-object exists with the given name, append the the x and y values
              if (plot) {
                plot.x.push(distanceToLineCenter);
                plot.y.push(intersection.z);
                plot.text.push(text);

                // Instantitate a new plot-object
              } else {
                // Fetch styling from "snittoppsett"
                const style = getPlotStyle(
                  layout,
                  layoutName || name.toLowerCase()
                );
                let color = "0,0,0";
                let lineStyle = "solid";
                if (style) {
                  color = style.farge;
                  lineStyle = style.stipling === "1" ? "dot" : "solid";
                }

                plots.push({
                  service: item.title,
                  name: name,
                  x: [distanceToLineCenter],
                  y: [intersection.z],
                  type: "scatter",
                  mode: "lines+markers",
                  marker: {
                    color: "rgb(" + color + ")",
                    size: item.title === "profil_tm_Ledninger" ? 3 : 1,
                  },
                  line: {
                    dash: lineStyle,
                  },
                  showlegend: showlegend,
                  text: [text],
                  legendgroup: group,
                  visible: true,
                });
              }
            });

            return;
          });
          return await Promise.all(featurePromises);
        }
      });
    }
  });

  await Promise.all(layerPromises);
  // Sort plots alphabetically
  plots.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  // Sort plot points on x-value
  plots.forEach((plot) => {
    [plot.x, plot.y, plot.text] = sortPlot(plot.x, plot.y, plot.text);
  });

  return plots;
};

const getPlotStyle = (layout, name) => {
  const styleArray = JSON.parse(layout);
  const style = styleArray.find((obj) => obj.lag.toLowerCase() === name);

  return style;
};

const sortPlot = (xList, yList, textList) => {
  if (xList.length !== yList.length)
    console.log("x and y does not have the same length");

  let list = [];
  let x = [];
  let y = [];
  let text = [];

  // Create list of objects with corresponding x,y pairs
  for (let i = 0; i < xList.length; i++)
    list.push({
      x: xList[i],
      y: yList[i],
      text: textList[i],
    });

  // Sort objects on x-value
  list.sort((a, b) => a.x - b.x);

  // Split into respective arrays
  list.forEach((obj) => {
    x.push(obj.x);
    y.push(obj.y);
    text.push(obj.text);
  });

  return [x, y, text];
};

const getInterserctionPoints = async (line, feature) => {
  let intersections = [];

  // Cut feature in intersection point
  let halfLines = await cut(feature.geometry, line);

  halfLines.forEach((halfLine) => {
    for (let i = 0; i < halfLine.paths.length; i++) {
      // check first point
      let p1 = halfLine.getPoint(i, 0);
      let p1_dist = getDistancePointToLine(p1, line);

      if (p1_dist < 0.0001) intersections.push(p1);
      // check last point
      let p2 = halfLine.getPoint(i, halfLine.paths[i].length - 1);
      let p2_dist = getDistancePointToLine(p2, line);
      if (p2_dist < 0.0001) intersections.push(p2);
    }
  });

  return intersections;
};

const get3dIntersectionsPoints = async (line, feature) => {
  let intersections = [];
  const paths = feature.geometry.paths[0];

  for (let i = 0; i < paths.length - 1; i++) {
    try {
      let featureSegment = new Polyline({
        hasZ: true,
        spatialReference: line.spatialReference.wkid,
        paths: [[paths[i], paths[i + 1]]],
      });
      // Cut feature in intersection point
      if (featureSegment) {
        let halfLines = await cut(featureSegment, line);

        halfLines.forEach((halfLine) => {
          for (let i = 0; i < halfLine.paths.length; i++) {
            // check first point
            let p1 = halfLine.getPoint(i, 0);
            let p1_dist = getDistancePointToLine(p1, line);

            if (p1_dist < 0.001) intersections.push(p1);
            // check last point
            let p2 = halfLine.getPoint(i, halfLine.paths[i].length - 1);
            let p2_dist = getDistancePointToLine(p2, line);
            if (p2_dist < 0.001) intersections.push(p2);
          }
        });
      }
    } catch (err) {
      console.log("Failed to fetch some 3D features", err);
    }
  }
  return intersections;
};

const getDistancePointToLine = (point, line) => {
  let linePoint = nearestCoordinate(line, point).coordinate; //nearest coordinate to the line

  //distance from center line to mouse pointer
  let distanceToCenterLine = distance(point, linePoint, "meters");

  return distanceToCenterLine;
};

export const setAllIntersectionPlots = async (
  mapview,
  line,
  lineLength,
  centerLine,
  clickPoint,
  layout,
  dispatch,
  currentProject,
  currentRoadModel,
  showClickPoint = true
) => {
  dispatch({ type: "SET_BUSY", isBusy: true });
  dispatch({
    type: "SET_PLOT_INFO",
    info: {
      plots: [],
      clickLine: null,
    },
  });

  let distanceToLineCenter =
    showClickPoint && intersects(clickPoint, line)
      ? clickPoint.distance(line.getPoint(0, 1))
      : Infinity;

  distanceToLineCenter = getXvalue(
    clickPoint,
    distanceToLineCenter,
    centerLine,
    line
  );
  const clickPointLine = GetClickPointLine(
    "tverrsnitt",
    distanceToLineCenter,
    lineLength
  );

  let intersectionPlots = await GetIntersectionPlots(
    mapview,
    line,
    layout,
    centerLine,
    currentProject,
    currentRoadModel
  );

  try {
    const imageServicePlots = await GetImageServicePlots(
      mapview,
      line,
      lineLength,
      clickPoint,
      centerLine
    );

    intersectionPlots = [...intersectionPlots, ...imageServicePlots];
  } catch (err) {
    dispatch({
      type: "SET_MESSAGE",
      message: "Uthenting av image service data feilet " + err,
    });
  }

  dispatch({
    type: "SET_PLOT_INFO",
    info: { plots: intersectionPlots, clickLine: clickPointLine },
  });
  dispatch({ type: "SET_BUSY", isBusy: false });
};

export const GetLengthProfilePlots = async (
  mapview,
  layout,
  centerLine,
  currentProject,
  currentRoadModel,
  dispatch,
  clickPointLine = null
) => {
  dispatch({ type: "SET_BUSY", isBusy: true });
  dispatch({
    type: "SET_PLOT_INFO",
    info: {
      plots: [],
      clickLine: null,
    },
  });
  const conditions = ["_ls_"];
  let plots = [];
  const wkid = mapview.spatialReference.wkid;

  // Loop through existing layers
  const layerPromises = mapview.map.layers.items.map(async (item) => {
    if (!item.title) return false;
    // Check if the layer title includes one of the conditional strings
    if (
      conditions.some((string) => item.title.toLowerCase().includes(string))
    ) {
      // Query intersecting lines
      const query = item.createQuery();
      query.returnGeometry = true;
      query.returnZ = true;
      query.where = `prosjektnavn = '${currentProject}' AND vegmodellnavn = '${currentRoadModel.name}' AND revisjon = '${currentRoadModel.revisjon}' AND mmi = '${currentRoadModel.mmi}'`;
      if (item.definitionExpression) {
        query.where = `${item.definitionExpression} AND ${query.where}`;
      }

      // Query intersectin features
      return item.queryFeatures(query).then(async (response) => {
        // Check if the layer intersects the line at the current position
        if (response.features.length !== 0) {
          // For each intersecting line
          const featurePromises = response.features.map(async (feature) => {
            const name = feature.attributes.tmlag;
            const paths = feature.geometry.paths[0];

            // Fetch styling from "snittoppsett"
            let style = getPlotStyle(layout, name.toLowerCase());
            let color = "0,0,0";
            let lineStyle = "solid";
            if (style) {
              color = style.farge;
              lineStyle = style.stipling === "1" ? "dot" : "solid";
            }
            // Create a new plot object
            let plot = {
              service: item.title,
              name: name,
              x: [
                calculateProfilNr(
                  new Point({
                    type: "point",
                    x: paths[0][0],
                    y: paths[0][1],
                    spatialReference: mapview.spatialReference.wkid,
                  }),
                  centerLine,
                  wkid
                ),
              ],
              y: [paths[0][2]],
              type: "scatter",
              mode: "lines+markers",
              marker: {
                color: "rgb(" + color + ")",
                size: 2,
              },
              line: {
                dash: lineStyle,
              },
              showlegend: true,
            };
            plots.push(plot);
            for (let i = 1; i < paths.length; i++) {
              plot.x.push(
                calculateProfilNr(
                  new Point({
                    type: "point",
                    x: paths[i][0],
                    y: paths[i][1],
                    spatialReference: mapview.spatialReference.wkid,
                  }),
                  centerLine,
                  wkid
                )
              );
              plot.y.push(paths[i][2]);
            }
            return;
          });
          return await Promise.all(featurePromises);
        }
      });
    }
  });

  await Promise.all(layerPromises);
  // Sort plots alphabetically
  plots.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  // Get Image server plot
  const imageServicePlots = await GetImageServicePlots(mapview, centerLine);
  plots = [...plots, ...imageServicePlots];

  dispatch({
    type: "SET_PLOT_INFO",
    info: { plots: plots, clickLine: clickPointLine },
  });
  dispatch({ type: "SET_BUSY", isBusy: false });

  return plots;
};

export const moveClickPoint = (
  mapview,
  dispatch,
  centerLine,
  profileNr,
  plots,
  movePlotLine = true
) => {
  mapview.graphics.removeAll();

  dispatch({
    type: "SET_INTERSECT_LINE_PROFIL_NR",
    profilNr: profileNr,
  });

  // Set new click point line in plot
  if (movePlotLine) {
    const clickPointLine = GetClickPointLine("lengdeprofil", profileNr);
    dispatch({
      type: "SET_PLOT_INFO",
      info: { plots: plots, clickLine: clickPointLine },
    });
  }
  // Move point in map
  const startProfileNr = centerLine.attributes.start_profilnr;
  const endProfileNr = Number(
    (startProfileNr + planarLength(centerLine.geometry, "meters")).toFixed(2)
  );
  const [x, y] = getPointAtProfileNr(
    profileNr - startProfileNr,
    centerLine.geometry.paths[0],
    endProfileNr
  );
  let point = new Point({
    type: "point",
    x: x,
    y: y,
    spatialReference: mapview.spatialReference.wkid,
  });
  let pointGraphic = new Graphic({
    geometry: point,
    symbol: {
      type: "simple-marker",
      color: "red",
      size: 8,
    },
    listMode: "hide",
  });
  mapview.graphics.add(pointGraphic);
};

const isLeft = (pl0, pl1, p) => {
  return (pl1.x - pl0.x) * (p.y - pl0.y) - (pl1.y - pl0.y) * (p.x - pl0.x) >= 0;
};

const getXvalue = (coordinate, xValue, centerLine, intersectLine) => {
  let nearestLinePoint = nearestCoordinate(
    centerLine.geometry,
    intersectLine.getPoint(0, 1)
  );

  const nextLinePoint = centerLine.geometry.getPoint(
    0,
    nearestLinePoint.vertexIndex + 1
  );
  if (
    nearestLinePoint.coordinate.x === nextLinePoint.x &&
    nearestLinePoint.coordinate.y === nextLinePoint.y
  ) {
    nearestLinePoint = centerLine.geometry.getPoint(
      0,
      nearestLinePoint.vertexIndex
    );
  }

  const left = isLeft(
    nearestLinePoint.coordinate ?? nearestLinePoint,
    nextLinePoint,
    coordinate
  );

  if (left) {
    return -xValue;
  }
  return xValue;
};
