import { useContext } from "react";
import { Context } from "../../Store";
import MuiStyles from "../../utils/MuiStyles";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { useEffect } from "react";
import { useState } from "react";

const SelectProjectMenu = () => {
  const [state, dispatch] = useContext(Context);
  const [title, setTitle] = useState("");
  const classes = MuiStyles();

  useEffect(() => {
    setTitle(state.currentProject.title || "Velg prosjekt");
  }, [state.currentProject.title]);

  const style =
    state.currentProject.title === "Velg prosjekt"
      ? classes.select
      : classes.selectBold;

  const onSelectProjectClick = () => {
    dispatch({ type: "SET_PROJECT_SELECTOR_IS_OPEN", open: true });
  };

  const getDisabled = () => {
    return state.projectSelectorIsOpen ? { disabled: true } : {};
  };

  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl fullWidth {...getDisabled()}>
        <Select
          labelId="select-project"
          onClick={onSelectProjectClick}
          variant="standard"
          IconComponent={() => <FolderOpenIcon />}
          open={false}
          value={title}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className={style}
        >
          <MenuItem value={title}>{title}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectProjectMenu;
