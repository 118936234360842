import { useRef, useEffect, useContext } from "react";
import "./MapComponent.css";
import { Context } from "../../Store";
import SetProjectList from "../../utils/SetProjectList";
import SetRoadModelList from "../../utils/SetRoadModelList";
import SetOverbygningslagList from "../../utils/SetOverbygningslagList";
import Authenticate from "../../utils/Authenticate";
import createIntersectLineGraphic from "../../utils/IntersectLine";
import {
  calculateProfilNr,
  generateMarkerGraphic,
  setDistanceToCenterLine,
} from "../../utils/CenterLineUtils";
import { moveClickPoint, setAllIntersectionPlots } from "../../utils/PlotUtils";
import { expandHandler } from "../../utils/ExpandHandler";
import FilterOverbygningslag from "../../utils/FilterOverbygningslag";
import ShowOnlyActiveRoadModel from "../../utils/ShowOnlyActiveRoadModel";
import { MapConfig } from "../../config/MapConfig";

import { nearestCoordinate } from "@arcgis/core/geometry/geometryEngine";
import Home from "@arcgis/core/widgets/Home";
import Zoom from "@arcgis/core/widgets/Zoom";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import Expand from "@arcgis/core/widgets/Expand";
import Measurement from "@arcgis/core/widgets/Measurement";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Basemap from "@arcgis/core/Basemap";
import TileLayer from "@arcgis/core/layers/TileLayer";
import Locate from "@arcgis/core/widgets/Locate";
import LayerSettings from "../layerSettings/LayerSettings";
import WmsHandler from "../../utils/WmsHandler";

const MapComponent = (props) => {
  const [state, dispatch] = useContext(Context);

  const mapDiv = useRef();
  const centerLineRef = useRef();
  const intersectLineRef = useRef();
  const plotterTypeRef = useRef();
  const profilNrRef = useRef();
  const intersectLineLengthRef = useRef();
  const roadModelRef = useRef();
  const showOnlyActiveRoadModelRef = useRef();
  const plotsRef = useRef();
  const propsRef = useRef();
  const projectListRef = useRef();

  useEffect(() => {
    Authenticate();
    SetProjectList(dispatch);
  }, [dispatch]);

  useEffect(() => {
    centerLineRef.current = state.centerLine;
  }, [state.centerLine]);

  useEffect(() => {
    plotterTypeRef.current = state.plotterType;

    // Popup handler
    if (!state.mapview) return;
    else if (plotterTypeRef.current === "none")
      state.mapview.popup.autoOpenEnabled = true;
    else if (
      plotterTypeRef.current === "tverrsnitt" ||
      plotterTypeRef.current === "lengdeprofil"
    ) {
      //closes popup if the tverrsnitt or lengdeprofil is on
      state.mapview.popup.close();
      state.mapview.popup.autoCloseEnabled = true;
      state.mapview.popup.autoOpenEnabled = false;
    }
  }, [state.plotterType, state.mapview]);

  useEffect(() => {
    intersectLineLengthRef.current = state.intersectLineLength;
  }, [state.intersectLineLength]);

  useEffect(() => {
    intersectLineRef.current = state.intersectLine;
    intersectLineRef.current = state.intersectLineLength;
  });

  useEffect(() => {
    roadModelRef.current = state.currentRoadModel;
  }, [state.currentRoadModel]);

  useEffect(() => {
    showOnlyActiveRoadModelRef.current = state.showOnlyActiveRoadModel;
  }, [state.showOnlyActiveRoadModel]);

  useEffect(() => {
    plotsRef.current = state.plotInfo.plots;
  }, [state.plotInfo.plots]);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  useEffect(() => {
    projectListRef.current = state.projectList;
    if (
      state.currentProject.id !== process.env.REACT_APP_PROJECT_WEBMAPID &&
      !state.currentProject.name
    ) {
      const currentProject = projectListRef.current.find(
        (project) => project.id === state.currentProject.id
      );
      if (currentProject) {
        dispatch({ type: "SET_CURRENT_PROJECT", project: currentProject });
      }
    }
  }, [state.projectList, state.currentProject, dispatch]);

  useEffect(() => {
    const intersectLineLayer = new GraphicsLayer({
      graphics: [],
      title: "intersectLineLayer",
      listMode: "hide",
    });

    dispatch({
      type: "SET_INTERSECT_LINE_LAYER_ID",
      intersectLineLayerId: intersectLineLayer.id,
    });
    //kun for punktet som beveger seg på senterlinjen
    let layerCenterLineLayer = new GraphicsLayer({
      graphics: [],
      listMode: "hide",
    });

    if (mapDiv.current) {
      const map = new WebMap({
        portalItem: {
          id: state.currentProject.id,
        },
      });

      const mapView = new MapView({
        map: map,
        container: "mapDiv",
        //removing default zoom in, zoom out buttons
        ui: {
          components: ["attribution"],
        },
        constraints: {
          rotationEnabled: false,
        },
      });
      document.getElementById("toolbarDiv").style.display = "none";

      mapView.when((mapView) => {
        dispatch({ type: "SET_MAPVIEW", mapview: mapView });

        // Show busy indicator while the map is loading on the initial rendering
        watchUtils.whenTrueOnce(mapView, "updating", () => {
          dispatch({ type: "SET_BUSY", isBusy: true });
        });
        watchUtils.whenFalseOnce(mapView, "updating", () => {
          dispatch({ type: "SET_BUSY", isBusy: false });
        });
        // Initialize project list
        if (
          state.currentProject.id !== process.env.REACT_APP_PROJECT_WEBMAPID
        ) {
          // Update road model list, overbygningslag list and filter overbygningslag on Overflate pr default
          SetRoadModelList(mapView, dispatch);
          SetOverbygningslagList(mapView, state.currentProject.name, dispatch);
          FilterOverbygningslag(
            mapView,
            "Overflate",
            state.currentProject.name,
            roadModelRef.current,
            showOnlyActiveRoadModelRef.current
          );
          if (
            state.currentProject.wms_url &&
            state.currentProject.wms_sublayers
          ) {
            WmsHandler(
              mapView,
              state.currentProject.wms_url,
              state.currentProject.wms_sublayers
            );
          }
        }

        mapView.on("click", async (event) => {
          if (
            measurementExpand.expanded === true &&
            (distanceButton.classList[3] === "active" ||
              areaButton.classList[3] === "active")
          ) {
            return;
          }

          let clickPoint = event.mapPoint;
          dispatch({ type: "SET_CLICK_POINT", point: clickPoint });
          const wkid = mapView.spatialReference.wkid;
          const centerLine = centerLineRef.current;

          // Only draw the intersection line and fetch data if the plot is open
          if (plotterTypeRef.current === "tverrsnitt" && centerLine) {
            mapView.graphics.removeAll();
            intersectLineLayer.removeAll();
            const intersectLineGraphic = createIntersectLineGraphic(
              event,
              centerLine,
              intersectLineLengthRef.current / 2,
              wkid
            );

            intersectLineLayer.add(intersectLineGraphic);
            map.add(intersectLineLayer);
            dispatch({
              type: "SET_INTERSECT_LINE_AND_PROFIL_NR",
              payload: [intersectLineGraphic.geometry, profilNrRef.current],
            });

            setAllIntersectionPlots(
              mapView,
              intersectLineGraphic.geometry,
              intersectLineLengthRef.current,
              centerLine,
              clickPoint,
              state.currentProject.intersectLayout,
              dispatch,
              state.currentProject.name,
              roadModelRef.current
            );
          }
          if (
            plotterTypeRef.current === "lengdeprofil" &&
            centerLineRef.current
          ) {
            mapView.graphics.removeAll();
            intersectLineLayer.removeAll();

            const nearestCenterLinePoint = nearestCoordinate(
              centerLine.geometry,
              clickPoint
            ).coordinate;

            const clickPointProfileNr = calculateProfilNr(
              nearestCenterLinePoint,
              centerLine,
              wkid
            );

            moveClickPoint(
              mapView,
              dispatch,
              centerLineRef.current,
              Number(clickPointProfileNr),
              plotsRef.current
            );
          }
        });

        mapView.on("hold", async (event) => {
          if (
            measurementExpand.expanded === true &&
            distanceButton.classList[3] === "active"
          ) {
            measurement.startMeasurement();
          } else if (
            measurementExpand.expanded === true &&
            areaButton.classList[3] === "active"
          ) {
            measurement2.startMeasurement();
          }
        });

        // Create line geometry to intersect road model center line
        mapView.on("pointer-move", function (event) {
          layerCenterLineLayer.removeAll();

          const centerLine = centerLineRef.current;
          if (!centerLine) return;
          //point where mouse pointer is
          let point = mapView.toMap(event);
          //distance from center line to mouse pointer
          let distanceToCenterLine = setDistanceToCenterLine(centerLine, point);

          //keeps just one point on the time- the one that is shown on the center line
          let pointGraphic = generateMarkerGraphic(point, centerLine);
          layerCenterLineLayer.graphics.add(pointGraphic);
          map.add(layerCenterLineLayer);

          //updates "Profilnr"
          let profilNr = calculateProfilNr(
            point,
            centerLine,
            mapView.spatialReference
          );

          profilNrRef.current = Number(profilNr);
          propsRef.current.onMarkerMove({
            profilNr: Number(profilNr),
            distance: distanceToCenterLine.toFixed(2),
          });
        });

        //create home button
        const homeButton = new Home({
          view: mapView,
          theme: "hus",
        });

        //zoom button
        const zoom = new Zoom({ view: mapView });

        //creates a basemapGallery widget
        const basemapGallery = new BasemapGallery({
          view: mapView,
          container: document.createElement("div"),
          source: MapConfig.basemaps.map((basemap) => {
            return new Basemap({
              id: basemap.title,
              baseLayers: [new TileLayer({ url: basemap.url })],
              title: basemap.title,
              thumbnailUrl: `../../images/${basemap.thumbnail}`,
            });
          }),
        });

        const basemapToggleExpand = new Expand({
          expandIconClass: "esri-icon-basemap",
          collapseIconClass: "esri-icon-basemap",
          content: basemapGallery,
          expandTooltip: "Bakgrunnskartvelger",
        });

        // Create new instance of 2 Measurement widgets
        //one for distance, other one for area
        const measurement = new Measurement({
          view: mapView,
          linearUnit: "meters",
        });

        const measurement2 = new Measurement({
          view: mapView,
          linearUnit: "meters",
          areaUnit: "square-meters",
        });

        const measurementExpand = new Expand({
          expandIconClass: "ruler",
          collapseIconClass: "ruler",
          view: mapView,
          content: document.getElementById("toolbarDiv"),
          expandTooltip: "Måleverktøy",
        });

        //locate widget
        const locateButton = new Locate({
          view: mapView,
        });

        mapView.ui.add(basemapToggleExpand, { position: "top-right" });
        mapView.ui.add(measurementExpand, { position: "top-right" });
        mapView.ui.add(homeButton, { position: "top-left" });
        mapView.ui.add(zoom, { position: "top-left" });
        mapView.ui.add(locateButton, { position: "top-left" });

        //handles 2 expands: measurement expand and baseMapToggleExpand
        expandHandler(basemapToggleExpand, measurementExpand);

        //measurement widget with funtionalities
        const distanceButton = document.getElementById("distance");
        const areaButton = document.getElementById("area");

        watchUtils.whenTrueOnce(measurementExpand, "expanded", function () {
          document.getElementById("toolbarDiv").style = "block";

          distanceButton.addEventListener("click", function () {
            mapView.popup.close();
            distanceMeasurement();
          });
          areaButton.addEventListener("click", function () {
            mapView.popup.close();
            areaMeasurement();
          });

          function distanceMeasurement() {
            measurement2.clear();
            measurement.activeTool = "distance";
            measurement.unit = "meters";
            if (areaButton.classList) areaButton.classList.remove("active");
            if (distanceButton.classList)
              distanceButton.classList.add("active");
            measurement.startMeasurement();
          }

          function areaMeasurement() {
            measurement.clear();
            measurement2.activeTool = "area";
            if (distanceButton.classList)
              distanceButton.classList.remove("active");
            if (areaButton.classList) areaButton.classList.add("active");
            measurement2.startMeasurement();
          }
        });

        // Clears all measurements
        watchUtils.whenFalse(measurementExpand, "expanded", () => {
          measurement.clear();
          measurement2.clear();
          if (distanceButton.classList)
            distanceButton.classList.remove("active");
          if (areaButton.classList) areaButton.classList.remove("active");
        });

        // Hide all other road models checkbox
        const hideModelsCheckbox = document.getElementById("checkboxDiv");
        mapView.ui.add(hideModelsCheckbox, { position: "top-right", index: 0 });

        // Open map layer list button
        const layerSettingsButton = document.getElementById(
          "layerSettingsButton"
        );
        mapView.ui.add(layerSettingsButton, {
          position: "top-right",
          index: 0,
        });

        const layerSettings = document.getElementById("layerSettings");
        mapView.ui.add(layerSettings);
      });
    }
  }, [state.currentProject, dispatch]);

  const handleCheckboxChange = (event) => {
    ShowOnlyActiveRoadModel(
      state.mapview,
      state.currentRoadModel,
      state.currentProject.name,
      event.target.checked,
      state.overbygningslag
    );

    dispatch({
      type: "SET_SHOW_ONLY_ACTIVE_ROAD_MODEL",
      value: event.target.checked,
    });
  };

  const handleLayerSettingsButtonClick = (event) => {
    dispatch({
      type: "SET_LAYER_SETTINGS_IS_OPEN",
      isOpen: !state.layerSettingsIsOpen,
    });
  };

  return (
    <div className="mapContainer" id="mapDiv" ref={mapDiv}>
      <div id="toolbarDiv">
        <button
          id="layerSettingsButton"
          className="esri-widget--button esri-interactive layerSettingsButton"
          title="Layer settings"
          type="button"
          onClick={handleLayerSettingsButtonClick}
        ></button>
        <button
          id="distance"
          className="esri-widget--button esri-interactive esri-icon-measure"
          title="Mål avstand"
          type="button"
        ></button>
        <button
          id="area"
          className="esri-widget--button esri-interactive esri-icon-measure-area"
          title="Mål area"
          type="button"
        ></button>
      </div>
      {state.currentRoadModel.name !== "" && (
        <div id="checkboxDiv">
          <div className="checkboxLayout">
            <input
              id="checkboxInput"
              type="checkbox"
              className="checkboxBox"
              checked={state.showOnlyActiveRoadModel}
              onChange={handleCheckboxChange}
            />
            <label id="checkboxLabel" className="checkboxLabel">
              Skjul andre vegmodeller
            </label>
          </div>
        </div>
      )}
      {state.layerSettingsIsOpen && <LayerSettings id="layerSettings" />}
    </div>
  );
};

export default MapComponent;
