import List from "@mui/material/List";

import LayerListItem from "./LayerListItem";

const LayerList = (props) => {
  return (
    <List sx={{ width: "100%" }} disablePadding>
      {props.layers.map(
        (layer, id) =>
          layer.listMode !== "hide" && (
            <LayerListItem layer={layer} key={id} id={id} />
          )
      )}
    </List>
  );
};

export default LayerList;
