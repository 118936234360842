import { useContext } from "react";
import { Context } from "../../Store";

import ZoomToRoadModel from "../../utils/ZoomToRoadModel";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const ZoomToRoadModelButton = () => {
  const [state] = useContext(Context);
  const getDisabled = () => {
    return state.currentRoadModel.name === "" ? { disabled: true } : {};
  };

  const color =
    state.currentRoadModel.name === ""
      ? { color: "#9C9C9C" }
      : { color: "#1978C9" };

  return (
    <Tooltip title="Zoom til vegmodell" label="zoom-to-road-model">
      <div>
        <IconButton
          aria-label="zoom-to-road-model"
          onClick={() =>
            ZoomToRoadModel(
              state.mapview,
              state.currentRoadModel,
              state.currentProject.name
            )
          }
          {...getDisabled()}
        >
          <LocationSearchingIcon sx={color} />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default ZoomToRoadModelButton;
