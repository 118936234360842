import { Polyline } from "@arcgis/core/geometry";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import Graphic from "@arcgis/core/Graphic";

/**
 * Creates a Graphic that is the normal of the centerline of a road model,
 * running through a point clicked in the map and the nearest coordinate on the
 * center line to the clicked point
 * @param {event} clickEvent The event generated from a user click in a map
 * @param {Polyline} centerLine The center line of the road model
 * @param {Number} distance length from the centerline to the end of the returned
 * Graphic. I.e. half the length of the returned Graphic
 * @returns {Graphic} Line Graphic of length distance that intersects the centerline
 * at 90 degrees and runs through the clicked point and the nearest point on the centerline
 */

const createIntersectLineGraphic = (
  clickEvent,
  centerLine,
  distance = 50,
  wkid
) => {
  // Find nearest point on road center line
  const nearestCoord = geometryEngine.nearestCoordinate(
    centerLine.geometry,
    clickEvent.mapPoint
  ).coordinate;
  const nearestPoint = [nearestCoord.x, nearestCoord.y];

  const nearestVertexIndex = geometryEngine.nearestVertex(
    centerLine.geometry,
    clickEvent.mapPoint
  ).vertexIndex;
  // Edge case: nearest point on the line is on either end of the line
  if (
    arrayEquals(
      nearestPoint,
      centerLine.geometry.paths[0][centerLine.geometry.paths[0].length - 1]
    ) ||
    arrayEquals(nearestPoint, centerLine.geometry.paths[0][0])
  ) {
    const intersectLineGraphic = createEdgeIntersectLine(
      centerLine,
      nearestVertexIndex,
      wkid,
      distance
    );
    return intersectLineGraphic;
  }

  // express click point and nearest coordinate as array of x,y values
  // Helper methods in this file expect points as two-dimensional array
  const clickPoint = [clickEvent.mapPoint.x, clickEvent.mapPoint.y];

  // calculate a point 50 meters away from nearest coordinate in the direction on clickPoint
  const expandedPoint = calculatePointAtDistance(
    clickPoint,
    nearestPoint,
    distance
  );

  // create path from expandedPoint that intersects nearestPoint and a point at equal length on opposite side of center line
  const intersectPath = calculateIntersectPath(nearestPoint, expandedPoint);

  // create line geometry from intersectPath
  const intersectLine = createIntersectLine(intersectPath, wkid);
  // Line graphic
  const intersectLineGraphic = createLineGraphic(intersectLine);

  return intersectLineGraphic;
};

/**
 * Creates a Graphic that extends the intersect line
 * @param {geometry} line geometry
 * @param {Number} distance length from the centerline to the end of the returned
 * Graphic.
 * @param {wkid} coordinate system
 * @returns {Graphic} Line Graphic of length distance that intersects the centerline
 * at 90 degrees and runs through the clicked point and the nearest point on the centerline
 */
export const expandIntersectLine = (intersectLine, distance, wkid) => {
  const point = intersectLine.paths[0][0];
  const centerPoint = intersectLine.paths[0][1];

  //calculate the point to be extended to
  const pointAtDistance = calculatePointAtDistance(
    point,
    centerPoint,
    distance
  );
  // create path from expandedPoint that intersects nearestPoint and a point at equal length on opposite side of center line
  const intersectPath = calculateIntersectPath(centerPoint, pointAtDistance);

  // create line geometry from intersectPath
  const newIntersectLine = createIntersectLine(intersectPath, wkid);
  // Line graphic
  const intersectLineGraphic = createLineGraphic(newIntersectLine);

  return intersectLineGraphic;
};

/**
 * Creates a Graphic that is the perpendicular to the centerline of a road model,
 * crossing the first or last vertex of the centerline.
 * @param {Polyline} centerLine The center line of the road model
 * @param {Number} index the index of the edge vertex. Should be either first or last
 * vertex, but would work for any vertex.
 * @param {wkid} wkid WKID for spatial reference of the geometry of the returned Graphic
 * @param {Number} distance length from the centerline to the end of the returned
 * Graphic. I.e. half the length of the returned Graphic
 * @returns {Graphic} Line Graphic of length distance that intersects the centerline
 * at 90 degrees in first or last vertex of the centerline
 */
export const createEdgeIntersectLine = (
  centerLine,
  index,
  wkid,
  distance = 50
) => {
  const paths = centerLine.geometry.paths[0];
  // closest point is at the first vertex of the centerline
  if (index > 0) {
    const edgeVertex = paths[paths.length - 1];
    const innerVertex = paths[paths.length - 2];
    const perpendicularVertex = calculatePerpendicular(
      edgeVertex,
      innerVertex,
      distance
    );
    const edgePoint = [edgeVertex[0], edgeVertex[1]];

    const intersectPaths = calculateIntersectPath(
      edgePoint,
      perpendicularVertex
    );
    const intersectLine = createIntersectLine(intersectPaths, wkid);
    const intersectLineGraphic = createLineGraphic(intersectLine);
    return intersectLineGraphic;
  }
  // closest point is at the first vertex of the centerline
  else {
    const edgeVertex = paths[0];
    const innerVertex = paths[1];
    const perpendicularVertex = calculatePerpendicular(
      edgeVertex,
      innerVertex,
      distance
    );

    const edgePoint = [edgeVertex[0], edgeVertex[1]];

    const intersectPaths = calculateIntersectPath(
      edgePoint,
      perpendicularVertex
    );
    const intersectLine = createIntersectLine(intersectPaths, wkid);
    const intersectLineGraphic = createLineGraphic(intersectLine);
    return intersectLineGraphic;
  }
};

/**
 * Generates a point at a given distance from the centerline such that a line
 * running through the generated point and edgeVertex given to the function crosses
 * the center line at 90 degrees.
 * @param {Array} edgeVertex Vertex at either start or end of centerline
 * @param {Array} innerVertex the vertex just before or just after edgeVertex in
 * centerline's paths-array
 * @param {Number} distance Distance at which the output point should be from the
 * centerline.
 * @returns {Array} array with x- and y-values representing a point 50 meters
 * meters from the edge of the centerline
 */
const calculatePerpendicular = (edgeVertex, innerVertex, distance = 50) => {
  // get x, y values for edge vertex and the vertex directly preceding or following it
  const x0 = edgeVertex[0];
  const y0 = edgeVertex[1];
  const x1 = innerVertex[0];
  const y1 = innerVertex[1];

  // Calculate deltas
  const dx = x1 - x0;
  const dy = y1 - y0;

  // slope of line (x0y0, x1y1)
  const m0 = dy / dx;

  // Given a straight line with slope m, it's perpendicular has slope -1/m
  const m1 = -1 / m0;

  // given slope m:
  // cos(arctan(m))) = 1/(sqrt(1+m^2))
  // sin(arctan(m)) = m/(sqrt(1+m^2))
  const r = Math.sqrt(1 + Math.pow(m1, 2));

  const x2 = x0 + distance / r;
  const y2 = y0 + (distance * m1) / r;

  return [x2, y2];
};

/**
 * Find a point, 50 meters from the center line, that lies on the line drawn form
 * a point clicked in the map and the nearest coordinate on the center line to
 * the click-point.
 * @param {Array} clickPoint Point where the user clicked in the map.
 * @param {Array} linePoint Point on the center line that is closest to the
 * clicked.
 * @returns {Array} Array containing x- and y-coordinates of a point
 * at a given distance in meters from nearest point that lies on the
 * line that intersects clickPoint and nearestPoint
 */
export const calculatePointAtDistance = (clickPoint, linePoint, distance) => {
  // coordinates of click point
  const x1 = clickPoint[0];
  const y1 = clickPoint[1];

  // coordinates of nearest point on the centerline to the clicked point.
  const x2 = linePoint[0];
  const y2 = linePoint[1];

  // calculate deltas
  const dx = x2 - x1;
  const dy = y2 - y1;

  // Slope of the line given by dy/dx
  const slope = dy / dx;
  // given slope m:
  // cos(arctan(m))) = 1/(sqrt(1+m^2))
  // sin(arctan(m)) = m/(sqrt(1+m^2))
  const r = Math.sqrt(1 + Math.pow(slope, 2));
  // x3 = x2 + distance * cos(arctan(m))
  const x3 = x2 + distance / r;
  // y3 = y2 + distance * m * cos(arctan(m))
  const y3 = y2 + (distance * slope) / r;

  return [x3, y3];
};

/**
 * Calculate path to create line intersecting centerline.
 * @param {Array} linePoint Center line of the road model
 * @param {Array} secondPoint a different point some distance from center line
 * such that a line drawn from linePoint to secondPoint forms a normal on the centerline
 * @returns {Array} Array containing x and y coordinates of click poin, nearest point on centerline and point on oposite side of centerline
 */
export const calculateIntersectPath = (linePoint, secondPoint) => {
  const x1 = secondPoint[0];
  const y1 = secondPoint[1];

  const x2 = linePoint[0];
  const y2 = linePoint[1];

  // Determine deltas
  const dx = x2 - x1;
  const dy = y2 - y1;

  // Point on oposite side of line is (x2+dx, y2+dy)
  const x3 = x2 + dx;
  const y3 = y2 + dy;

  return [
    [x1, y1],
    [x2, y2],
    [x3, y3],
  ];
};

/**
 * Create line geometry with spatial reference wkid 102100 given a path
 * @param {Array} path array of coordinates through which to create line geometry
 * @returns {Polyline} Polyline with spatialReference 102100 that runst through
 * the coordinates in path
 */
export const createIntersectLine = (path, wkid) => {
  let intersectLine = new Polyline({
    hasZ: false,
    hasM: true,
    paths: path,
    spatialReference: { wkid: wkid },
  });
  return intersectLine;
};

/**
 * Create line graphic given a polyline geometry
 * @param {Polyline} geometry Polyline geometry used to create Graphic
 * @returns {Graphic} Graphic of Polyline
 *
 */
export const createLineGraphic = (geometry) => {
  let symbol = {
    type: "simple-line",
    color: [255, 0, 0],
    width: 2,
  };
  let lineGraphic = new Graphic({
    geometry: geometry,
    symbol: symbol,
    attributes: {},
    listMode: "hide",
  });
  return lineGraphic;
};

export const distanceBetweenPoints = (x1, y1, x2, y2) => {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

export const createIntersectLineAtProfilnr = (
  linePoint,
  nextPoint,
  wkid,
  length
) => {
  const perpendicularVertex = calculatePerpendicular(
    linePoint,
    nextPoint,
    length
  );

  const edgePoint = [linePoint[0], linePoint[1]];

  const intersectPaths = calculateIntersectPath(edgePoint, perpendicularVertex);
  const intersectLine = createIntersectLine(intersectPaths, wkid);
  const intersectLineGraphic = createLineGraphic(intersectLine);
  return intersectLineGraphic;
};

export const moveIntersectLine = (
  distance,
  mapview,
  paths,
  lineLength,
  endProfileNr
) => {
  mapview.graphics.removeAll();
  const wkid = mapview.spatialReference.wkid;
  // Remove the old intersect lines

  const [x, y, index] = getPointAtProfileNr(distance, paths, endProfileNr);

  // Create the intersecting line using the next centerline vertex unless an end point is reached, then use the previous point
  const lineGraphic =
    distance === endProfileNr
      ? createIntersectLineAtProfilnr(
          [x, y],
          paths[paths.length - 2],
          wkid,
          lineLength / 2
        )
      : createIntersectLineAtProfilnr(
          [x, y],
          paths[index + 1],
          wkid,
          lineLength / 2
        );

  if (lineGraphic) return lineGraphic;
  else return null;
};

export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const getPointAtProfileNr = (distance, paths, endProfileNr) => {
  // The point to draw the new intersection line
  let [x, y] = [null, null];
  let travelledDist = 0;
  let prevDist = 0;
  let index = 0;
  let restDist = 0;

  // Case: first point
  if (distance === 0) [x, y] = [paths[0][0], paths[0][1]];
  // Case: end point
  else if (distance === endProfileNr)
    [x, y] = [paths[paths.length - 1][0], paths[paths.length - 1][1]];
  // Case: otherwise
  else {
    // Run through each vertext of the centerline and add the distance from the previous to the next point to the distance travelled.
    for (let i = 1; i < paths.length; i++) {
      prevDist = travelledDist;
      travelledDist += distanceBetweenPoints(
        paths[i - 1][0],
        paths[i - 1][1],
        paths[i][0],
        paths[i][1]
      );
      // When the total travelled distance  passes the chosen profilnr, the index and the remainding distance of the previous vertex is saved
      if (travelledDist > distance) {
        index = i - 1;
        restDist =
          paths[index + 1][0] > paths[index][0]
            ? distance - prevDist
            : prevDist - distance;

        break;
      }
    }

    // Calculate the point at the given distance from the previous vertex point
    [x, y] =
      restDist !== 0
        ? calculatePointAtDistance(paths[index + 1], paths[index], restDist)
        : [paths[index][0], paths[index][1]];
  }
  return [x, y, index];
};
export default createIntersectLineGraphic;
