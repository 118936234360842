// import Query from "@arcgis/core/rest/support/Query";

/**
 * Zoom the map to the selected road model feature.
 *
 * @param {mapView} mapview The active mapView object.
 * @param {string} currentRoadModel The id of the selected road model.
 * @returns {void}
 *
 */
const ZoomToRoadModel = (mapview, currentRoadModel, project) => {
  const roadModelLayer = mapview.map.layers.items.find(
    (item) => item.title === "Senterlinje"
  );
  if (!roadModelLayer) {
    console.log("No feature layer found");
    return;
  }
  const query = roadModelLayer.createQuery();
  query.where = `prosjektnavn = '${project}' AND vegmodellnavn = '${currentRoadModel.name}' AND revisjon = '${currentRoadModel.revisjon}' AND mmi = '${currentRoadModel.mmi}'`;
  roadModelLayer.queryFeatures(query).then((response) => {
    if (response.features.length === 0) {
      console.log("INVALID ROAD MODEL ID");
    } else {
      mapview.goTo(response.features[0]);
    }
  });
};

export default ZoomToRoadModel;
