import {
  distance,
  nearestCoordinate,
  planarLength,
} from "@arcgis/core/geometry/geometryEngine";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";

/**
 * Creates a Graphic that is the point that moves on the centerline of a road model,
 * @param {point}
 * @param {event} pointerMove The event generated from a mouse moves
 * @returns {Graphic} Point Graphic that moves on the centerline
 */
export const generateMarkerGraphic = (point, centerLine) => {
  if (!centerLine) {
    console.log("CENTER LINE IS NOT DEFINED");
  }
  //spesifiserer hvordan punktet ser ut
  let markerSymbol = {
    type: "simple-marker",
    style: "circle",
    color: "white",

    outline: {
      color: { r: 1, g: 57, b: 90 },
      width: 10,
      style: "solid",
    },
  };

  // Finds the coordinate of the centrelineGeometry which is closest to the mouse
  let nearestCoordinateOfTheLine = nearestCoordinate(
    centerLine.geometry,
    point
  ).coordinate; //nearest coordinate to the line

  //graphics that moves on the center line
  let pointGraphic = new Graphic({
    geometry: nearestCoordinateOfTheLine,
    symbol: markerSymbol,
    listMode: "hide",
  });

  return pointGraphic;
};

/**
 * Creates
 * @param {centerLine} The center line of the road model
 * @param {point}
 * @returns {number} distance to center line
 */
export const setDistanceToCenterLine = (centerLine, point) => {
  // Finds the coordinate of the centrelineGeometry which is closest to the mouse
  let nearestCoordinateOfTheLine = nearestCoordinate(
    centerLine.geometry,
    point
  ).coordinate; //nearest coordinate to the line

  //distance from center line to mouse pointer
  let distanceToCenterLine = distance(
    point,
    nearestCoordinateOfTheLine,
    "meters"
  );

  return distanceToCenterLine;
};

/**
 * Creates
 * @param {point}
 * @param {centerLine}
 * @param {spatialReference}
 * @returns {profilNr}
 */

export const calculateProfilNr = (point, centerLine, spatialReference) => {
  //nearest coordinate to the line
  let nearestPointResult = nearestCoordinate(centerLine.geometry, point);
  let nearestCoordinateOfTheLine = nearestPointResult.coordinate;

  //fins the vertex index
  let vertexIndex = nearestPointResult.vertexIndex;

  //array of paths, that make up the center line
  let paths = centerLine.geometry.paths;
  //copies coordinates of the center line into another array
  let paths2 = [...paths];
  //delets unneeded coordinates of copied line
  paths2[0] = paths2[0].slice(0, vertexIndex + 1);

  //adds the point where you currently are into the line
  paths2[0].push([nearestCoordinateOfTheLine.x, nearestCoordinateOfTheLine.y]);

  let line2 = new Polyline({
    hasZ: false,
    hasM: true,
    paths: paths2,
    spatialReference: spatialReference,
  });

  //calculates profilNr
  let lengthOfCenterLine = planarLength(line2, "meters");
  let profilNr = (
    centerLine.attributes.start_profilnr + lengthOfCenterLine
  ).toFixed(2);

  return profilNr;
};
