import "./LayerSettings.css";
import { useContext, useState } from "react";
import { Context } from "../../Store";
import LayerList from "./LayerList";
import OverbygningslagList from "./OverbygningslagList";

const LayerSettings = () => {
  const [state, dispatch] = useContext(Context);
  const [showLayerList, setShowLayerList] = useState(true);
  const [showOverbygningslagList, setShowOverbygningslagList] = useState(false);

  const onExitClick = () => {
    dispatch({ type: "SET_LAYER_SETTINGS_IS_OPEN", isOpen: false });
  };

  const layers = state.mapview.map.layers.filter((layer) =>
    layer.title ? !layer.title.toLowerCase().includes(["profil_"]) : false
  );

  const onExpandOverbygningslagClick = () => {
    setShowOverbygningslagList(!showOverbygningslagList);
  };
  const onExpandLayerListClick = () => {
    setShowLayerList(!showLayerList);
  };
  return (
    <div id="layerSettingsContainer">
      <div className="header">
        <span className="header-text">Kartlagsinnstillinger</span>
        <button className="header-exit" onClick={onExitClick}></button>
      </div>
      <div>
        <div className="subheader" onClick={onExpandOverbygningslagClick}>
          <span className="subheader-text">Overbygningslag</span>
          <button className="subheader-expand"></button>
        </div>
        {showOverbygningslagList && (
          <div className="content-overbygningslag">
            {" "}
            <OverbygningslagList
              overbygningslagList={state.overbygningslagList}
            />
          </div>
        )}{" "}
      </div>
      <div>
        <div className="subheader" onClick={onExpandLayerListClick}>
          <span className="subheader-text">Kartlag</span>
          <button className="subheader-expand"></button>
        </div>
        {showLayerList && (
          <div className="content-layer-list">
            {" "}
            <LayerList layers={layers} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LayerSettings;
