import Query from "@arcgis/core/rest/support/Query";

/**
 *
 * Fetches a list of unique values of "overbygningslag" from the feature layer "omriss" and sets the list in global state. Also sets the default selected value to "Overflate"
 *
 * @param {string} project The name of the active project
 * @param {function} dispatch The function for setting the global state.
 * @returns {void}
 *
 */
const SetOverbygningslagList = (mapview, project, dispatch) => {
  let result = [];

  const query = new Query({
    where: `prosjektnavn = '${project}' AND prosjektnavn IS NOT NULL`,
    outFields: "overbygningslag",
    returnDistinctValues: true,
  });

  const karttjenester = mapview.map.layers.items.find(
    (item) => item.title === "Karttjenester"
  );

  if (karttjenester) {
    mapview.whenLayerView(karttjenester).then(() => {
      const outlineLayer = karttjenester.allSublayers.find(
        (layer) => layer.title === "Omriss"
      );
      if (outlineLayer) {
        outlineLayer.queryFeatures(query).then(async (response) => {
          const promises = response.features.map(async (feature) => {
            result = [...result, feature.attributes.overbygningslag].filter(
              (value) => value !== null
            );
          });
          await Promise.all(promises);
          dispatch({ type: "SET_OVERBYGNINGSLAG_LIST", list: result });
          dispatch({
            type: "SET_OVERBYGNINGSLAG",
            overbygningslag: "Overflate",
          });
        });
      } else {
        console.log(
          "Kan ikke hente overbygningslagliste. Finner ikke 'Omriss'"
        );
      }
    });
  }
};

export default SetOverbygningslagList;
