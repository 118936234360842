import { useContext, useState, useEffect } from "react";
import { Context } from "../../Store";
import HighlightRoadModel from "../../utils/HighlightRoadModel";
import "./LayerListItem.css";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ListIcon from "@mui/icons-material/List";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Collapse from "@mui/material/Collapse";
import Legend from "@arcgis/core/widgets/Legend";
import Tooltip from "@mui/material/Tooltip";

const LayerListItem = (props) => {
  const [state, dispatch] = useContext(Context);
  const [layerListOpen, setLayerListOpen] = useState(true);
  const [symbologyOpen, setSymbologyOpen] = useState(false);
  const [checked, setChecked] = useState(props.layer.visible);
  const hasSublayers = props.layer.allSublayers ? true : false;

  useEffect(() => {
    if (!props.isSublayer) {
      new Legend({
        view: state.mapview,
        container: document.getElementById(props.layer.title),
        headingLevel: 6,
        layerInfos: [{ layer: props.layer, title: "" }],
      });
    }
  }, [props.layer, state.mapview, props.isSublayer]);

  const handleOnLayerClick = (event, layer) => {
    if (event.target.checked) {
      layer.visible = true;
    } else {
      layer.visible = false;
    }

    if (layer.title === "Senterlinje" && state.currentRoadModel.name) {
      HighlightRoadModel(
        state.mapview,
        state.currentRoadModel,
        state.currentProject.name,
        state.highlightSelect,
        dispatch,
        event.target.checked
      );
    }
    setChecked(event.target.checked);
  };

  const handleExpandLayerGroupClick = () => {
    setLayerListOpen(!layerListOpen);
  };

  const handleShowSymbologyClick = () => {
    setSymbologyOpen(!symbologyOpen);
  };

  const getChecked = () => {
    if (props.parentChecked) return checked;
    else return props.parentChecked;
  };
  const layerStyle = {
    flexDirection: "column",
    width: "280px",
    left: "0",
    alignItems: "stretch",
  };
  const sublayerStyle = {
    flexDirection: "column",
    width: "270px",
    left: "30px",
    alignItems: "stretch",
  };

  let legendLabel = props.layer.title;
  if (props.layer.title === "Geoserver Skanska") {
    legendLabel = "Dronefoto";
  }
  if (!legendLabel) {
    legendLabel = props.layer.name;
  }

  return (
    <ListItem
      value={props.layer.title}
      key={props.id}
      disablePadding
      sx={props.isSublayer ? sublayerStyle : layerStyle}
      component="div"
    >
      <ListItemButton
        dense
        sx={{ padding: "0", margin: "0", alignItems: "left" }}
      >
        <ListItemIcon sx={{ minWidth: "0px" }}>
          <Checkbox
            onChange={(event) => handleOnLayerClick(event, props.layer)}
            checked={props.isSublayer ? getChecked() : checked}
            sx={{
              height: "16px",
              width: "16px",
              paddingRight: "20px",
            }}
          />
        </ListItemIcon>

        <ListItemText primary={legendLabel} />
        {hasSublayers && (
          <ListItemIcon
            sx={{ left: "0" }}
            onClick={handleExpandLayerGroupClick}
          >
            <Tooltip title="Vis sublag">
              <IconButton sx={{ width: "10px", height: "10px" }}>
                {layerListOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
              </IconButton>
            </Tooltip>
          </ListItemIcon>
        )}

        {!props.isSublayer && (
          <ListItemIcon sx={{ right: "0" }} onClick={handleShowSymbologyClick}>
            <Tooltip title="Vis tegnforklaring">
              <IconButton sx={{ width: "10px", height: "10px" }}>
                <ListIcon />
              </IconButton>
            </Tooltip>
          </ListItemIcon>
        )}
      </ListItemButton>

      {hasSublayers && (
        <Collapse in={layerListOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.layer.allSublayers.map((sublayer, id) => (
              <LayerListItem
                layer={sublayer}
                key={id}
                id={id}
                isSublayer={true}
                parentChecked={checked}
              />
            ))}
          </List>
        </Collapse>
      )}

      <Collapse in={symbologyOpen} timeout="auto">
        <div id={props.layer.title} className="esri-widget-legend legend" />
      </Collapse>
    </ListItem>
  );
};

export default LayerListItem;
