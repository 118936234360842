import esriConfig from "@arcgis/core/config.js";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import Portal from "@arcgis/core/portal/Portal";

/**
 * Authentication configurations. Sets "https://gis.skanska.no/portal" as portal for the project
 * and handles user authentication.
 *  @returns {void}
 */
const Authenticate = () => {
  const portalUrl = process.env.REACT_APP_PORTALURL;
  esriConfig.portalUrl = portalUrl;

  var info = new OAuthInfo({
    appId: process.env.REACT_APP_APPID,
    portalUrl: esriConfig.portalUrl,
    expiration: 1440,
    flowType: "implicit",
  });
  const portal = new Portal({
    url: portalUrl,
  });

  IdentityManager.registerOAuthInfos([info]);
  IdentityManager.getCredential(esriConfig.portalUrl + "/sharing").then(
    (res) => {
      portal.load();
    }
  );
};

export default Authenticate;
