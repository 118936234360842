/**
 * Queries "kartlag_senterline" after the road models beloning to the active project.
 * Sets the list of road models in the global state.
 *
 * @param {mapView} mapview The active mapView object.
 * @param {function} dispatch The function for setting the global state.
 * @returns {void}
 *
 */
const SetRoadModelList = (mapview, dispatch) => {
  // Fetch webmap: Kartlag_senterlinje
  const roadModelLayer = mapview.map.layers.items.find(
    (item) => item.title === "Senterlinje"
  );

  if (!roadModelLayer) {
    console.log("No feature layer found");
    return;
  }
  const query = roadModelLayer.createQuery();
  query.where = "vegmodellnavn IS NOT NULL";
  if (roadModelLayer.definitionExpression) {
    query.where = `${query.where} AND ${roadModelLayer.definitionExpression}`;
  }
  roadModelLayer.queryFeatures(query).then((response) => {
    const roadModelList = response.features.sort(
      (a, b) => a.attributes.vegmodellnavn - b.attributes.vegmodellnavn
    );
    dispatch({
      type: "SET_ROAD_MODEL_LIST",
      roadModels: roadModelList,
    });
  });
};

export default SetRoadModelList;
