import classes from "./ButtonRow.module.css";

const ButtonRow = (props) => {
  const getClasses = (item) => {
    if (item === props.selected)
      return `${classes.button} ${classes.buttonSelected}`;
    else if (
      props.compareAsNumber &&
      parseInt(item, 10) > 0 &&
      parseInt(item, 10) === parseInt(props.selected, 10)
    )
      return `${classes.button} ${classes.buttonSelected}`;
    else return `${classes.button}`;
  };

  return (
    <div className={classes.buttonRow}>
      {props.buttons.map((b) => (
        <button
          key={b}
          className={getClasses(b)}
          onClick={() => props.onClick(b)}
        >
          {b}
        </button>
      ))}
    </div>
  );
};

export default ButtonRow;
