import makeStyles from "@mui/styles/makeStyles";

const basisBlue = "#1978C9";

const MuiStyles = makeStyles({
  select: {
    fontFamily: "SkanskasansRegular",
    fontSize: 14,
    color: basisBlue,
    borderBottom: "1px solid " + basisBlue,
  },
  selectBold: {
    fontFamily: "SkanskasansRegular",
    fontSize: 14,
    fontWeight: "bold",
    color: basisBlue,
    borderBottom: "1px solid " + basisBlue,
  },
  icon: {
    fill: basisBlue,
  },
  checkboxLabel: {
    fontSize: 14,
  },
});

export default MuiStyles;
