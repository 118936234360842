import classes from "./MeasureToolbar.module.css";

const MeasureToolbar = (props) => {
  //Modes:
  //0=No Anotations
  //1=One visible annotation, Measure not startet;
  //2=One visible annotation, Measure startet;
  //3=One or more active annotations, one inactive visible anotation, Measure startet;
  let toolbarMode = 0;

  const visibleCount = props.annotations.filter((i) => i.visible).length;
  const activeCount = props.annotations.filter((i) => i.active).length;

  if (props.annotations.length > 0 && visibleCount > 0) {
    toolbarMode = 1;
  }
  if (toolbarMode === 1 && props.isMessuring) {
    toolbarMode = 2;
  }
  if (toolbarMode === 2 && visibleCount > activeCount) {
    toolbarMode = 3;
  }

  let startButtonClass = `${classes.hidden}`;
  let addButtonClass = `${classes.hidden}`;
  let stopButtonClass = `${classes.hidden}`;

  switch (toolbarMode) {
    case 1:
      startButtonClass = `${classes.button} ${classes.start} ${classes.up}`;
      break;
    case 2:
      startButtonClass = `${classes.button} ${classes.start}`;
      stopButtonClass = `${classes.button} ${classes.stop} ${classes.up}`;
      break;
    case 3:
      stopButtonClass = `${classes.button} ${classes.stop} ${classes.up}`;
      addButtonClass = `${classes.button} ${classes.add} ${classes.up}`;
      break;
    default:
  }

  return (
    <div className={classes.measureToolbar}>
      <button disabled={toolbarMode > 1}
        onClick={() => props.OnButtonClick("MEASURE_START")}
        title="Start måling"
        className={startButtonClass}
      >
        <span></span>
      </button>
      <button
        onClick={() => props.OnButtonClick("MEASURE_ADD")}
        title="Legg til punkt i målingen"
        className={addButtonClass}
      >
        <span></span>
      </button>
      <button
        onClick={() => props.OnButtonClick("MEASURE_STOP")}
        title="Avslutt måling"
        className={stopButtonClass}
      >
        <span></span>
      </button>
    </div>
  );
};

export default MeasureToolbar;
