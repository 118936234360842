import Query from "@arcgis/core/rest/support/Query";

/**
 * Highlight the selected road model feature.
 *
 * @param {mapView} mapview The active mapView object.
 * @param {string} currentRoadModel The id of the selected road model.
 * @param {string} highlightSelect The highlighted feature
 * @param {function} dispatch The dispatch method for manipulating the global state
 * @returns {void}
 *
 */
const HighlightRoadModel = async (
  mapview,
  currentRoadModel,
  currentProject,
  highlightSelect,
  dispatch,
  toggleOn = true
) => {
  const roadModelLayer = mapview.map.layers.items.find(
    (item) => item.title === "Senterlinje"
  );
  const query = new Query({
    where: `prosjektnavn = '${currentProject}' AND vegmodellnavn = '${currentRoadModel.name}' AND revisjon = '${currentRoadModel.revisjon}' AND mmi = '${currentRoadModel.mmi}'`,
    outFields: "*",
    returnGeometry: true,
    outSpatialReference: mapview.spatialReference.wkid,
  });

  const result = roadModelLayer.queryFeatures(query).then(async (response) => {
    if (response.features.length === 0) {
      console.log("INVALID ROAD MODEL ID");
    } else {
      return await mapview
        .whenLayerView(roadModelLayer)
        .then(async (layerView) => {
          if (highlightSelect) {
            highlightSelect.remove();
          }
          //henter senterLinje for hver vegmodell
          if (toggleOn) {
            dispatch({
              type: "SET_HIGHLIGHT_SELECT",
              highlightSelect: layerView.highlight(response.features[0]),
            });
            dispatch({
              type: "SET_CENTERLINE",
              centerLine: response.features[0],
            });
          }
          return response.features[0];
        });
    }
  });
  return await result;
};

export default HighlightRoadModel;
