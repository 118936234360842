import classes from "./MessageComponent.module.css";
import { Context } from "../../Store";
import { useContext, useEffect, useRef } from "react";

const MessageComponent = () => {
  const [ctx, dispatch] = useContext(Context);
  const messagesRef = useRef([]);
  const timerRef = useRef(null);
  const styleRef = useRef(`${classes.messageComponent}`);

  if (ctx.messages.length > 0) {
    messagesRef.current.push(...ctx.messages);
    styleRef.current = `${classes.messageComponent} ${classes.messageComponentActive}`;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      styleRef.current = `${classes.messageComponent}`;
      dispatch({ type: "SET_MESSAGES", messages: [] });
      messagesRef.current = [];
    }, 5000);
  }

  useEffect(() => {
    if (ctx.messages.length > 0) {
      dispatch({ type: "SET_MESSAGES", messages: [] });
    }
  }, [ctx.messages, dispatch]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  messagesRef.current = [...new Set(messagesRef.current)];

  return (
    <div className={styleRef.current}>
      {messagesRef.current.map((m, index) => (
        <span key={index}> {m} </span>
      ))}
    </div>
  );
};

export default MessageComponent;
