const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_MAPVIEW":
      return {
        ...state,
        mapview: action.mapview,
      };
    case "SET_CURRENT_PROJECT":
      return {
        ...state,
        currentProject: action.project,
      };
    case "SET_CURRENT_ROAD_MODEL":
      return {
        ...state,
        currentRoadModel: action.model,
      };
    case "SET_PROJECT_LIST":
      return {
        ...state,
        projectList: action.projects,
      };
    case "SET_ROAD_MODEL_LIST":
      return {
        ...state,
        roadModelList: action.roadModels,
      };
    case "SET_PROJECT_SELECTOR_IS_OPEN":
      return {
        ...state,
        projectSelectorIsOpen: action.open,
      };
    case "SET_HIGHLIGHT_SELECT":
      return {
        ...state,
        highlightSelect: action.highlightSelect,
      };
    case "SET_DISTANCE_TO_CENTER_LINE":
      return {
        ...state,
        distanceToCenterLine: action.distance,
      };
    case "SET_CENTERLINE":
      return {
        ...state,
        centerLine: action.centerLine,
      };
    case "SET_PROFIL_NR":
      return {
        ...state,
        profilNr: action.profilNr,
      };
    case "SET_INTERSECT_LINE_PROFIL_NR":
      return {
        ...state,
        intersectLineProfilNr: action.profilNr,
      };
    case "SET_INTERSECT_LINE":
      return {
        ...state,
        intersectLine: action.intersectLine,
      };
    case "SET_INTERSECT_LINE_AND_PROFIL_NR":
      return {
        ...state,
        intersectLine: action.payload[0],
        intersectLineProfilNr: action.payload[1],
      };
    case "SET_INTERSECT_LINE_LENGTH":
      return {
        ...state,
        intersectLineLength: action.length,
      };
    case "SET_PLOTTER_TYPE":
      return {
        ...state,
        plotterType: action.plotter,
      };
    case "SET_BUSY":
      return {
        ...state,
        busy: action.isBusy,
      };
    case "SET_PLOT_INFO":
      return {
        ...state,
        plotInfo: action.info,
      };
    case "RESET_PLOT":
      return {
        ...state,
        intersectLineProfilNr: 0,
        intersectLine: {},
        intersectLineLength: 100,
        plotInfo: {
          plots: [],
          clickLine: null,
        },
      };
    case "SET_OVERBYGNINGSLAG":
      return {
        ...state,
        overbygningslag: action.overbygningslag,
      };
    case "SET_OVERBYGNINGSLAG_LIST":
      return {
        ...state,
        overbygningslagList: action.list,
      };

    case "SET_INTERSECT_LINE_LAYER_ID":
      return {
        ...state,
        intersectLineLayerId: action.intersectLineLayerId,
      };

    case "SET_MESSAGE":
      return {
        ...state,
        messages: [...state.messages, action.message],
      };

    case "SET_MESSAGES":
      return {
        ...state,
        messages: action.messages,
      };
    case "SET_CLICK_POINT":
      return {
        ...state,
        clickPoint: action.point,
      };
    case "SET_SHOW_ONLY_ACTIVE_ROAD_MODEL":
      return { ...state, showOnlyActiveRoadModel: action.value };
    case "SET_LAYER_SETTINGS_IS_OPEN":
      return { ...state, layerSettingsIsOpen: action.isOpen };
    default:
      return state;
  }
};

export default Reducer;
