import { Grid } from "@mui/material";
import ProjectCard from "./ProjectCard";
import { useContext } from "react";
import { Context } from "../../Store";

const ProjectList = (props) => {
  const [state] = useContext(Context);

  // Initialize correct number of columns. Max column number is 4 and occures when there are more than 4 or more projects in the projectList.
  const nColumns =
    state.projectList.length < 4 ? 12 / state.projectList.length : 3;

  // To center grid elements: justifyContent="center"
  return (
    <Grid container spacing={3} overflow="auto">
      {state.projectList.map((project, id) => (
        <Grid
          item
          key={id}
          xs={12}
          sm="auto"
          md={nColumns}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ProjectCard project={project} onClickShare={props.onClickShare} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectList;
