/**
 * Filters the layers "flatekantlinjer" and "omriss" on the selected overbygninslag.
 *
 * @param {mapView} mapview The active mapView object.
 * @param {string} overbygningslag The selected overbygningslag
 * @param {string} currentProject The current project name
 * @param {string} currentRoadModel The current road model name
 * @param {boolean} showOnlyActiveRoadModel Value that decides if the mapview should also filter on selected road model
 * @returns {void}
 *
 */

const FilterOverbygningslag = (
  mapview,
  overbygningslag,
  currentProject,
  currentRoadModel,
  showOnlyActiveRoadModel
) => {
  const karttjenester = mapview.map.layers.items.find(
    (item) => item.title === "Karttjenester"
  );
  karttjenester.allSublayers.forEach((subLayer) => {
    if (subLayer.title === "Omriss" || subLayer.title === "Flatekantlinjer") {
      if (currentRoadModel.name !== "" && showOnlyActiveRoadModel)
        subLayer.definitionExpression = `overbygningslag = '${overbygningslag}' AND prosjektnavn = '${currentProject}' AND vegmodellnavn = '${currentRoadModel.name}' AND revisjon = '${currentRoadModel.revisjon}' AND mmi = '${currentRoadModel.mmi}'`;
      else
        subLayer.definitionExpression =
          subLayer.definitionExpression = `overbygningslag = '${overbygningslag}' AND prosjektnavn = '${currentProject}'`;
    }
  });
};

export default FilterOverbygningslag;
