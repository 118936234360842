import { useContext } from "react";
import ProjectList from "./ProjectList";
import { Context } from "../../Store";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "6rem",
  paddingBottom: "6rem",
};

const boxStyle = {
  background: "transparent",
  maxWidth: "930px",
  height: "90%",
  outline: "none",
  overflow: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
  "&::-webkit-scrollbar": {
    backgroundColor: "transparent",
  },
};

const ProjectSelector = () => {
  const [state, dispatch] = useContext(Context);

  const handleClose = () => {
    dispatch({ type: "SET_PROJECT_SELECTOR_IS_OPEN", open: false });
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      closeAfterTransition={true}
      open={state.projectSelectorIsOpen}
      onClose={handleClose}
      style={modalStyle}
    >
      <Fade in={state.projectSelectorIsOpen}>
        <Box sx={boxStyle}>
          <ProjectList />
        </Box>
      </Fade>
    </Modal>
  );
};

export default ProjectSelector;
