import React, { useContext, useEffect, useRef } from "react";
import { Context } from "../../Store";
import classes from "./PlotHeader.module.css";
import ButtonRow from "./ButtonRow";
import {
  expandIntersectLine,
  moveIntersectLine,
} from "../../utils/IntersectLine";
import { moveClickPoint, setAllIntersectionPlots } from "../../utils/PlotUtils";
import { planarLength } from "@arcgis/core/geometry/geometryEngine";

const PlotHeader = (props) => {
  const profileNumberInput = useRef();
  const [ctx, dispatch] = useContext(Context);
  let clicks = 0;

  const setNewValue = (value) => {
    const startProfileNr = ctx.centerLine.attributes.start_profilnr;
    const endProfileNr = Number(
      (
        startProfileNr + planarLength(ctx.centerLine.geometry, "meters")
      ).toFixed(2)
    );
    if (isNaN(value)) {
      profileNumberInput.current.value = ctx.intersectLineProfilNr;
      return;
    }
    let newValue = null;

    if (value >= startProfileNr && value <= endProfileNr) {
      newValue = value;
    } else if (value < startProfileNr) {
      newValue = startProfileNr;
    } else if (value > endProfileNr) {
      newValue = endProfileNr;
    } else {
      console.log(value);
    }
    dispatch({ type: "SET_INTERSECT_LINE_PROFIL_NR", profilNr: newValue });
    profileNumberInput.current.value = newValue;

    if (ctx.plotterType === "tverrsnitt") {
      const newIntersectLineGraphic = moveIntersectLine(
        newValue - startProfileNr,
        ctx.mapview,
        ctx.centerLine.geometry.paths[0],
        ctx.intersectLineLength,
        endProfileNr
      );

      if (newIntersectLineGraphic) {
        const intersectLineLayer = ctx.mapview.map.findLayerById(
          ctx.intersectLineLayerId
        );
        if (intersectLineLayer) {
          intersectLineLayer.removeAll();
          intersectLineLayer.add(newIntersectLineGraphic);
        }
        setAllIntersectionPlots(
          ctx.mapview,
          newIntersectLineGraphic.geometry,
          ctx.intersectLineLength,
          ctx.centerLine,
          ctx.clickPoint,
          ctx.currentProject.intersectLayout,
          dispatch,
          ctx.currentProject.name,
          ctx.currentRoadModel,
          false
        );
      } else console.log("No intersecting line was created");
    } else {
      moveClickPoint(
        ctx.mapview,
        dispatch,
        ctx.centerLine,
        newValue,
        ctx.plotInfo.plots
      );
    }
  };

  const inputChangedHandler = (event) => {
    const timer = setTimeout(() => {
      setNewValue(parseFloat(event.target.value));
    }, 3000);

    return () => clearTimeout(timer);
  };

  const clickArrowHandler = (value) => {
    value = parseFloat(value);
    clicks += value;
    profileNumberInput.current.value = ctx.intersectLineProfilNr + clicks;

    const timer = setTimeout(() => {
      if (clicks !== 0) {
        const nextValue = ctx.intersectLineProfilNr + clicks;
        setNewValue(nextValue);
      }
      clicks = 0;
    }, 3000);
    return () => clearTimeout(timer);
  };

  const setLength = (argument) => {
    const length = parseInt(argument.replace("m", ""), 10);
    const intersectLineLayer = ctx.mapview.map.findLayerById(
      ctx.intersectLineLayerId
    );
    const geometry = intersectLineLayer.graphics.items[0].geometry;

    // remove old graphic
    intersectLineLayer.removeAll();
    // create new line graphic of given length
    const newIntersectLine = expandIntersectLine(geometry, length / 2, 25833);
    // add the line to the graphic layer
    intersectLineLayer.add(newIntersectLine);
    dispatch({ type: "SET_INTERSECT_LINE_LENGTH", length: length });
    setAllIntersectionPlots(
      ctx.mapview,
      newIntersectLine.geometry,
      length,
      ctx.centerLine,
      ctx.clickPoint,
      ctx.currentProject.intersectLayout,
      dispatch,
      ctx.currentProject.name,
      ctx.currentRoadModel
    );
  };

  useEffect(
    () => (profileNumberInput.current.value = ctx.intersectLineProfilNr)
  );

  return (
    <div className={classes.plotHeader}>
      <div className={classes.plottHandle}>---</div>
      <div className={classes.plotHeaderTitle}>
        Vegmodell: {ctx.currentRoadModel.name}
      </div>
      <div className={classes.plotHeaderProfilnummer}>
        <div className="arrow-left" onClick={() => clickArrowHandler(-1)}></div>
        <input
          className={classes.profilnummerInput}
          type="text"
          ref={profileNumberInput}
          defaultValue={ctx.intersectLineProfilNr.toFixed(2)}
          placeholder="0"
          onChange={(event) => inputChangedHandler(event)}
        />
        <div className="arrow-right" onClick={() => clickArrowHandler(1)}></div>
      </div>
      <div className={classes.plotHeaderTools}>
        {ctx.plotterType === "tverrsnitt" && (
          <ButtonRow
            buttons={["100m", "200m", "500m"]}
            selected={ctx.intersectLineLength}
            onClick={setLength}
            compareAsNumber={true}
          />
        )}
        <ButtonRow
          buttons={["Auto", "1:1", "1:5", "1:10"]}
          selected={props.scale}
          onClick={props.setScale}
          compareAsNumber={false}
        />
      </div>
    </div>
  );
};

export default PlotHeader;
