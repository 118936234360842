import WebMap from "@arcgis/core/WebMap";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

/**
 * Fetches the project list, sorts it alphabetically, fetches the thumbnail for each project webmap and sets the list in global state.
 *
 * @param {function} dispatch The function for setting the global state.
 * @returns {void}
 *
 */

const SetProjectList = async (dispatch) => {
  let projects = [];
  let invalidProjects = "";

  const prosjektinfoLayer = new FeatureLayer({
    url: process.env.REACT_APP_ROJECTLIST,
  });

  if (!prosjektinfoLayer) {
    console.log("Prosjektinfo service not found");
    return;
  }
  const query = prosjektinfoLayer.createQuery();
  prosjektinfoLayer.queryFeatures(query).then(async (response) => {
    //Fetch project information for each project
    const promises = response.features.map(async (project) => {
      let map = new WebMap({
        portalItem: {
          id: project.attributes.id_webmap,
        },
      });

      return await map.load().then(
        (map) => {
          let projectItem = {
            id: project.attributes.id_webmap,
            title: map.portalItem.title,
            name: project.attributes.kortnavn,
            client: project.attributes.oppdragsgiver,
            verticalRef: project.attributes.høydesystem,
            spatialRef: project.attributes.koordinatsystem,
            imageUrl:
              map.thumbnailUrl == null
                ? process.env.REACT_APP_DEFAULT_MAP_THUMBNAILURL
                : map.thumbnailUrl,
            intersectLayout: project.attributes.snittoppsett,
            wms_url: project.attributes.dronewmsurl,
            wms_sublayers: project.attributes.dronewmslayers
              ? project.attributes.dronewmslayers
                  .split(",")
                  .map((sublayer) => ({
                    name: sublayer,
                  }))
              : "",
          };

          projects = [...projects, projectItem];
        },
        () => {
          invalidProjects = invalidProjects.concat(
            "- " +
              project.attributes.prosjektnavn +
              " (" +
              project.attributes.id_webmap +
              ")\n"
          );
        }
      );
    });

    await Promise.all(promises);

    // Alphabetical sort
    if (projects.length > 1) {
      projects.sort((a, b) =>
        a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
      );
    }

    if (invalidProjects !== "") {
      alert(
        "Følgende prosjekter mangler/har ugyldig webmapId: \n\n" +
          invalidProjects
      );
    }
    dispatch({ type: "SET_PROJECT_LIST", projects: projects });
  });
};

export default SetProjectList;
