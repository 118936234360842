import WMSLayer from "@arcgis/core/layers/WMSLayer";

const WmsHandler = (mapview, url, sublayers) => {
  const layer = new WMSLayer({
    url: url,
    sublayers: sublayers,
  });

  mapview.map.add(layer, 0);
};

export default WmsHandler;
