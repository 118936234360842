const GEODATA_SERVER_URL =
  "https://services.geodataonline.no/arcgis/rest/services/";

export const MapConfig = {
  basemaps: [
    {
      url:
        GEODATA_SERVER_URL +
        "Geocache_UTM33_EUREF89/GeocacheGraatone/MapServer",
      title: "Gråtone",
      thumbnail: "thumbnail_graa.jpg",
    },
    {
      url:
        GEODATA_SERVER_URL + "Geocache_UTM33_EUREF89/GeocacheBasis/MapServer",
      title: "Basis",
      thumbnail: "thumbnail_basis.jpg",
    },
    {
      url:
        GEODATA_SERVER_URL + "Geocache_UTM33_EUREF89/GeocacheBilder/MapServer",
      title: "Bilder",
      thumbnail: "thumbnail_bilder.jpg",
    },
    {
      url:
        GEODATA_SERVER_URL +
        "Geocache_UTM33_EUREF89/GeocacheLandskap/MapServer",
      title: "Landskap",
      thumbnail: "thumbnail_landskap.jpg",
    },
  ],
};
