import { useContext } from "react";
import { Context } from "../../Store";
import FilterOverbygningslag from "../../utils/FilterOverbygningslag";
import MuiStyles from "../../utils/MuiStyles";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const OverbygningslagList = (props) => {
  const [state, dispatch] = useContext(Context);
  const classes = MuiStyles();

  const handleChange = (event) => {
    dispatch({
      type: "SET_OVERBYGNINGSLAG",
      overbygningslag: event.target.value,
    });

    FilterOverbygningslag(
      state.mapview,
      event.target.value,
      state.currentProject.name,
      state.currentRoadModel,
      state.showOnlyActiveRoadModel
    );
  };
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="Overflate"
        name="radio-buttons-group"
        onChange={handleChange}
      >
        {props.overbygningslagList.map((overbygningslag, id) => (
          <FormControlLabel
            value={overbygningslag}
            control={<Radio />}
            label={overbygningslag}
            key={id}
            sx={{ padding: "0", height: "25px", fontSize: "16px" }}
            classes={{
              label: classes.checkboxLabel,
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default OverbygningslagList;
