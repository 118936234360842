import { useContext } from "react";
import { Context } from "../../Store";
import HighlightRoadModel from "../../utils/HighlightRoadModel";
import MuiStyles from "../../utils/MuiStyles";
import ShowOnlyActiveRoadModel from "../../utils/ShowOnlyActiveRoadModel";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterOverbygningslag from "../../utils/FilterOverbygningslag";
import { GetLengthProfilePlots } from "../../utils/PlotUtils";

const SelectRoadModelMenu = () => {
  const [state, dispatch] = useContext(Context);

  const getDisabled = () => {
    return state.currentProject.id === process.env.REACT_APP_PROJECT_WEBMAPID
      ? { disabled: true }
      : {};
  };

  const classes = MuiStyles();
  const style =
    state.currentRoadModel.name === "" ? classes.select : classes.selectBold;

  const handleChange = async (event, model) => {
    const roadModel = {
      name: model.props.model.attributes.vegmodellnavn || event.target.value,
      mmi: model.props.model.attributes.mmi || "",
      revisjon: model.props.model.attributes.revisjon || "",
    };

    const centerLine = await HighlightRoadModel(
      state.mapview,
      roadModel,
      state.currentProject.name,
      state.highlightSelect,
      dispatch
    );
    dispatch({
      type: "SET_CURRENT_ROAD_MODEL",
      model: roadModel,
    });
    ShowOnlyActiveRoadModel(
      state.mapview,
      roadModel,
      state.currentProject.name,
      state.showOnlyActiveRoadModel,
      state.overbygningslag
    );

    FilterOverbygningslag(
      state.mapview,
      state.overbygningslag,
      state.currentProject.name,
      roadModel,
      state.showOnlyActiveRoadModel
    );

    if (state.plotInfo.plots) {
      state.mapview.graphics.removeAll();
      dispatch({ type: "RESET_PLOT" });
      // remove old graphic
      const intersectLineLayer = state.mapview.map.findLayerById(
        state.intersectLineLayerId
      );
      if (intersectLineLayer) intersectLineLayer.removeAll();
    }

    if (state.plotterType === "lengdeprofil") {
      GetLengthProfilePlots(
        state.mapview,
        state.currentProject.intersectLayout,
        centerLine,
        state.currentProject.name,
        event.target.value,
        dispatch
      );
    }
  };

  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl
        fullWidth
        {...getDisabled()}
        classes={{ disabled: classes.disabled }}
      >
        <Select
          labelId="select-road-model"
          value={state.currentRoadModel.name || ""}
          onChange={handleChange}
          variant="standard"
          displayEmpty
          inputProps={{
            "aria-label": "Without label",
          }}
          className={style}
        >
          <MenuItem value="">
            <em>Velg vegmodell</em>
          </MenuItem>
          {state.roadModelList.map((model, id) => (
            <MenuItem
              value={model.attributes.vegmodellnavn}
              model={model}
              key={id}
            >
              {model.attributes.vegmodellnavn} Rev {model.attributes.revisjon}{" "}
              MMI {model.attributes.mmi}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectRoadModelMenu;
