import { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
  mapview: "",
  currentProject: {
    id: process.env.REACT_APP_PROJECT_WEBMAPID,
    name: "",
    title: "Velg prosjekt",
    client: "",
    verticalRef: "",
    spatialRef: "",
    imageUrl: "",
    intersectLayout: "",
    wms_url: "",
    wms_sublayers: [],
  },
  currentRoadModel: { name: "", revisjon: "", mmi: "" },
  projectList: [],

  roadModelList: [],
  highlightSelect: "",
  projectSelectorIsOpen: true,
  distanceToCenterLine: 0,
  profilNr: 0,
  intersectLineProfilNr: 0,
  centerLine: null,
  plotterType: "none",
  intersectLine: {},
  intersectLineLength: 100,
  busy: true,
  plotInfo: {
    plots: [],
    clickLine: null,
  },
  overbygningslag: "Overflate",
  overbygningslagList: [],
  intersectLineLayerId: "",
  messages: [],
  clickPoint: null,
  showOnlyActiveRoadModel: false,
  layerSettingsIsOpen: false,
};

const Store = ({ children, initialProjectId }) => {
  const urlState = initialProjectId
    ? {
        ...initialState,
        projectSelectorIsOpen: false,
        currentProject: {
          ...initialState.currentProject,
          id: initialProjectId,
        },
      }
    : initialState;
  const [state, dispatch] = useReducer(Reducer, urlState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
