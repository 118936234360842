// Functions for adding and removing annotations (text boxes) in the plot.
// Annotations that are part of a measurement is flagged with the property active.
// NB: At the time of writing, Plotly for react has a bug that causes plotly to crash
// if an annotation is removed from the annotation array. To work around this, existing 
// annotations that should be deleted or replaced is set to visible=false.


const logAnnotations = (annotations) => {
    annotations.map((m) => {
        console.log("text:", m.text);
        console.log("visible:", m.visible);
        console.log("active:", m.active);
        console.log("-----");
        return null;
    });
};

const createNewAnnotation = (points) => {
    const newAnnotations = [];
    for (var i = 0; i < points.length; i++) {
        const annotate_text =
            "<b>" +
            points[i].data.name +
            "</b><br>" +
            points[i].x.toFixed(2) +
            " meter til senterlinje" +
            "<br>" +
            points[i].y.toFixed(2) +
            " m.o.h";

        const annotation = {
            text: annotate_text,
            bgcolor: "#FFFFFF",
            bordercolor: "#E2E7F3",
            borderpad: 3,
            align: "left",
            ay: -50,
            ax: 0,
            tag: 1,
            visible: true,
            x: points[i].x,
            y: parseFloat(points[i].y.toPrecision(4)),
        };
        newAnnotations.push(annotation);
    }
    return newAnnotations;
};

const hideAllAnnotations = (annotations) => {
    const result = [...annotations].map((item) => {
        item.visible = false;
        item.active = false;
        return item;
    });
    return result;
};

const hideInActiveAnnotations = (annotations) => {
    const result = [...annotations].map((item) => {
        if (!item.active) item.visible = false;
        return item;
    });
    return result;
};

const startMeasuring = (annotations) => {
    const newAnnotations = hideAllAnnotations(annotations);
    let startItem = { ...newAnnotations.at(-1) };
    startItem.text = `${startItem.text} <br>`;
    startItem.active = true;
    startItem.visible = true;
    startItem.bordercolor = "#1176CF";
    startItem.bgcolor = "#F3F5FB"
    return [...newAnnotations, startItem];
};

const calculateMeasures = (annotations, currentItem) => {
    const activeAnotations = annotations.filter((item) => item.active);
    const lastActive = activeAnotations.pop();
    const lengthX = Math.abs(lastActive.x - currentItem.x);
    const lengthY = (lastActive.y - currentItem.y) * -1;
    const length = Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2));
    const slope = (lengthY / lengthX) * 100;
    const result = `${length.toFixed(2)} meter avstand<br>${lengthX.toFixed(2)} m. hor. avstand<br>${lengthY.toFixed(2)} m. vert. avstand<br>${slope.toFixed(2)}% helling`;
    return result;
};

const addActiveAnnotations = (annotations) => {
    const result = hideInActiveAnnotations(annotations);
    const lastItem = { ...result.at(-1) };
    lastItem.active = true;
    lastItem.visible = true;
    lastItem.text = lastItem.text + "<br>" + calculateMeasures(annotations, lastItem);
    lastItem.ay = -80;
    lastItem.bordercolor = "#1176CF";
    lastItem.bgcolor = "#F3F5FB"
    result.push(lastItem);
    return result;
};

const getMessureLines = (annotations) => {
    let oldX;
    let oldY;
    const result = [];
    console.log('yes', oldX);
    annotations.filter(f => f.active).forEach(item => {
        if (oldX) {
            result.push(
                {
                    type: "line",
                    x0: oldX,
                    y0: oldY,
                    x1: item.x,
                    y1: item.y,
                    line: {
                        color: "rgb(0,120,201)",
                        width: 3,
                    }
                }
            );
        }
        oldX = item.x;
        oldY = item.y;
    });
    return result;
}

const AnnotationUtils = {
    logAnnotations,
    hideAllAnnotations: hideAllAnnotations,
    hideInActiveAnnotations: hideInActiveAnnotations,
    addActiveAnnotations: addActiveAnnotations,
    getMessureLines: getMessureLines,
    createNewAnnotation: createNewAnnotation,
    startMeasuring: startMeasuring
};

export default AnnotationUtils;
