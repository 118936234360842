import { useContext } from "react";
import MapComponent from "./MapComponent";
import PlotComponent from "../plot/PlotComponent";
import { Context } from "../../Store";
import classes from "./ProjectMap.module.css";

const ProjectMap = (props) => {
  const [state] = useContext(Context);

  return (
    <div className={classes.mainArea}>
      <MapComponent
        onMarkerMove={(profilNr, distance) =>
          props.onMarkerMove(profilNr, distance)
        }
      />
      {state.plotterType !== "none" && <PlotComponent />}
      {props.children}
    </div>
  );
};

export default ProjectMap;
