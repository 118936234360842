import Plot from "react-plotly.js";
import React, { useContext, useEffect, useState } from "react";
import MeasureToolbar from "./MeasureToolbar";
import { Context } from "../../Store";
import "./PlotComponent.css";
import PlotHeader from "./PlotHeader";
import AnnotationUtils from "../../utils/AnnotationUtils";
import { GetClickPointLine, moveClickPoint } from "../../utils/PlotUtils";

const PlotComponent = () => {
  const [ctx, dispatch] = useContext(Context);
  const [plots, setPlots] = useState([]);
  const [scale, setScale] = useState("1:1");
  const [clickLine, setClickLine] = useState(null);
  const [annotations, setAnnotations] = useState([]);
  const [messureLines, setMessureLines] = useState([]);
  const [isMeasuring, setMeasuring] = useState(false);
  const [range, setRange] = useState(null);

  useEffect(() => {
    setPlots(ctx.plotInfo.plots);
    setClickLine(ctx.plotInfo.clickLine);
    setAnnotations([]);
    setMessureLines([]);
    setMeasuring(false);
    setRange(null);
  }, [ctx.plotInfo]);

  //Handles scaling (ratio between x and y) 1:1 1:5 1:10
  let yaxis = {};
  switch (scale) {
    case "1:1":
      yaxis = {
        scaleanchor: "x",
        scaleratio: 1,
      };
      break;
    case "1:5":
      yaxis = {
        scaleanchor: "x",
        scaleratio: 5,
      };
      break;
    case "1:10":
      yaxis = {
        scaleanchor: "x",
        scaleratio: 10,
      };
      break;
    default:
      yaxis = {};
  }

  let missingPreconditionMessage = "";
  if (!ctx.currentRoadModel.name) {
    missingPreconditionMessage = "Velg vegmodell";
  } else if (ctx.plotterType === "tverrsnitt" && !ctx.intersectLine.paths) {
    missingPreconditionMessage = "Klikk i kartet for å generere tverrsnitt";
  }

  const onToolbarClick = (button) => {
    switch (button) {
      case "MEASURE_START":
        setMeasuring(true);
        setAnnotations(AnnotationUtils.startMeasuring(annotations));
        break;
      case "MEASURE_ADD":
        const addedAnnotations =
          AnnotationUtils.addActiveAnnotations(annotations);
        setAnnotations(addedAnnotations);
        setMessureLines(AnnotationUtils.getMessureLines(addedAnnotations));
        break;
      case "MEASURE_STOP":
        setMeasuring(false);
        setAnnotations([]);
        setMessureLines([]);
        break;
      default:
      // code block
    }
  };

  const onPlotClick = (data) => {
    const newAnnotations = AnnotationUtils.createNewAnnotation(data.points);
    if (isMeasuring) {
      setAnnotations([
        ...AnnotationUtils.hideInActiveAnnotations(annotations),
        ...newAnnotations,
      ]);
    } else {
      setAnnotations([
        ...annotations.map((m) => {
          m.visible = false;
          return m;
        }),
        ...newAnnotations,
      ]);
    }

    // Move point on center line and click point in plot to the selected point's profile number
    if (ctx.plotterType === "lengdeprofil") {
      const profilNrClick = data.points[0].x;
      setRange({
        xrange: data.points[0].xaxis.range,
        yrange: data.points[0].yaxis.range,
      });
      moveClickPoint(
        ctx.mapview,
        dispatch,
        ctx.centerLine,
        profilNrClick,
        ctx.plotInfo.plots,
        false
      );
      const clickPointLine = GetClickPointLine("lengdeprofil", profilNrClick);
      setClickLine(clickPointLine);
    }
  };

  return (
    <>
      {!missingPreconditionMessage && (
        <div className="plot-container">
          <PlotHeader scale={scale} setScale={setScale} />
          <Plot
            className="plot"
            data={plots}
            onClick={onPlotClick}
            config={{ responsive: true, scrollZoom: true }}
            layout={{
              margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 50,
                pad: 4,
              },
              yaxis: range !== null ? { range: range.yrange } : yaxis,
              xaxis: range !== null ? { range: range.xrange } : {},
              shapes: [clickLine, ...messureLines],
              annotations: annotations,
              legend: {
                tracegroupgap: 0,
                //entrywidth: 300,
              },
              dragmode: "pan",
              hoverlabel: {
                namelength: 30,
              },
              uirevision: true,
            }}
          />
          <MeasureToolbar
            annotations={annotations}
            isMessuring={isMeasuring}
            OnButtonClick={onToolbarClick}
          />
        </div>
      )}
      {missingPreconditionMessage && (
        <div className="noPlotInfoContainer">
          {" "}
          <div className="noPlotInfo">{missingPreconditionMessage}</div>
        </div>
      )}
    </>
  );
};

export default PlotComponent;
