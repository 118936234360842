import Store from "./Store";
import ProjectSelector from "./components/projects/ProjectSelector";
import ProjectMap from "./components/map/ProjectMap";
import Spinner from "./components/spinner/Spinner";
import MainNav from "./components/layout/MainNav";
import MessageComponent from "./components/message/MessageComponent";
import { useRef } from "react";

console.log("Current environment settings", process.env.REACT_APP_ENV_TAG);
console.log("Current portal url", process.env.REACT_APP_PORTALURL);

const App = () => {
  let markerRef = useRef("start");
  const urlString = new URLSearchParams(window.location.search);
  const initialProjectId = urlString.get("id");

  return (
    <Store initialProjectId={initialProjectId}>
      <MainNav marker={markerRef} />
      <main
        style={{
          position: "absolute",
          width: "100%",
          height: "90%",
        }}
      >
        <ProjectMap
          onMarkerMove={(p) => {
            markerRef.current && markerRef.current(p);
          }}
        />
        <ProjectSelector />
      </main>
      <Spinner />
      <MessageComponent />
    </Store>
  );
};

export default App;
