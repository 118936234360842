import { useContext } from "react";
import { Context } from "../../Store";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import { CardActionArea } from "@mui/material";

const ProjectCard = (props) => {
  const [state, dispatch] = useContext(Context);

  const onCardClick = () => {
    dispatch({
      type: "SET_CURRENT_PROJECT",
      project: props.project,
    });
    dispatch({ type: "SET_PROJECT_SELECTOR_IS_OPEN", open: false });
    dispatch({ type: "SET_CURRENT_ROAD_MODEL", model: "" });
    dispatch({ type: "RESET_PLOT" });
    dispatch({ type: "SET_SHOW_ONLY_ACTIVE_ROAD_MODEL", value: false });
    dispatch({ type: "SET_LAYER_SETTINGS_IS_OPEN", isOpen: false });

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("id", props.project.id);
    window.history.replaceState(null, null, "?" + queryParams.toString());
  };

  // Highlight current project
  const cardStyle =
    props.project.title === state.currentProject.title
      ? {
          height: "100%",
          width: "200px",
          padding: "5px",
          boxShadow: "3",
          alignSelf: "center",
          backgroundColor: "rgba(256,256,256, 1)",
        }
      : {
          height: "100%",
          width: "200px",
          padding: "5px",
          border: "none",
          boxShadow: "0",
          alignSelf: "center",
          backgroundColor: "rgba(256,256,256,0.7)",
        };

  return (
    <Card style={cardStyle}>
      <CardActionArea onClick={onCardClick}>
        <CardMedia
          component="img"
          height="133px"
          image={props.project.imageUrl}
          alt="Error loading image"
        />
        <CardHeader
          title={props.project.title}
          titleTypographyProps={{
            variant: "a",
            size: "18",
            fontWeight: "bold",
          }}
        />
      </CardActionArea>
    </Card>
  );
};

export default ProjectCard;
